@import '../commonStyle.module.scss';

.banner3Inner {
  width: 100%;
  height: 360px;
  background-color: $C_JOBDA_BLACK;
  background-image: url(../@assets/mainBg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .star {
    margin-bottom: 32px;
  }
}
