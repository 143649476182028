@import '../detail.module.scss';

.detailInfoArea {
  display: flex;
  flex-direction: column;
  gap: 56px;

  // 상세 안내 이미지
  &:after {
    flex: none;
    width: 328px;
    height: 80px;
    margin: 0 auto;
    background-image: url(../../@assets/imgDetailInfo.png);
    background-size: cover;
    content: '';
  }
}

.detailInfoInner {
  &:after {
    content: none;
  }
}

// 상세 안내 리스트
.detailInfoList {
  .listItem {
    display: flex;
    flex-direction: column;
    width: 328px;
    margin: 0 auto;
    padding: 16px 0;
    border-bottom: 1px solid $C_COOL_GRAY_80;
    text-align: left;

    &:first-child {
      padding-top: 0;
    }
  }

  .infoTitle {
    flex: none;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: $C_WHITE;
  }

  .infoDataArea {
    flex: none;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .infoDataInner {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-left: 8px;

    &:before {
      position: absolute;
      top: 8px;
      left: 0;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: $C_COOL_GRAY_60;
      content: '';
    }
  }

  .infoData {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.12px;
    color: $C_COOL_GRAY_60;
  }

  .infoDataDesc {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.12px;
    color: $C_COOL_GRAY_60;
  }
}
