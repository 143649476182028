@import '../../matchIntroduce.module.scss';

.mainVisual {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url(../../@assets/mainBg.png);
  background-size: cover;
  background-position: center;
}
