@import '../commonStyle.module.scss';

.eventTitle {
  margin-bottom: 40px;
  width: 120px;
  height: 38px;
  background-image: url(../@assets/event.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.eventFinish {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 328px;
  height: 209px;
  margin-top: 16px;

  .finishText {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);

    .title {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      color: #fff;
    }

    .subTitle {
      margin-top: 16px;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      color: #fff;
    }
  }

  .eventCoffee {
    width: 196px;
    height: 160px;
    background-image: url(../@assets/coffee.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

/* 이벤트 참여 */
.eventHow {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  margin-top: 40px;

  .eventHowTitle {
    position: relative;
    padding-top: 16px;
    text-align: left;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.2px;
    color: $C_JOBDA_BLACK;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 24px;
      height: 4px;
      background: #121619;
    }
  }
}

.eventHowContent {
  display: flex;
  flex-direction: column;

  .eventBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .eventBoxTitle {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;

      .num {
        display: block;
        width: 24px;
        height: 24px;
        vertical-align: middle;
        border-radius: 16px;
        background: $C_JOBDA_BLACK;
        font-size: 10px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: -0.1px;
        color: $C_WHITE;
      }

      .mainText {
        font-size: 16px;
        color: $C_JOBDA_BLACK;
        font-weight: 700;
        line-height: 20px;
      }

      .subText {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: -0.14px;
        color: $C_COOL_GRAY_70;
      }
    }
  }

  .btnWhite {
    &:disabled {
      border: 1px solid #cfd1d5;
      color: rgba($C_COOL_GRAY_60, 0.64);
      background-color: transparent;
    }

    .btnIcon {
      opacity: 0.64;
    }
  }

  .btnBlack {
    &:disabled {
      border-radius: 4px;
      background: #e7e8ea;
      border: none;
      @include fontStyle($B2_B, $C_COOL_GRAY_60);
    }
  }
}

/* 버튼 영역 */
.eventBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-top: 24px;

  .btnClick {
    width: 272px;
    border-radius: 4px;
    border: 1px solid $C_JOBDA_BLACK;

    &.btnBlack {
      width: 272px;
    }
  }
}

.eventLine {
  margin: 40px 0;
  width: 100%;
  height: 1px;
  background: #cfd1d5;
}
