@import '../matchIntroduce.module.scss';

.commonBox {
  position: relative;
  padding-top: 0;
  overflow: hidden;

  .subTitle {
    margin: 0 0 32px 0; //기존 마진 탑 0 처리
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 50px;
    right: 50%;
    transform: translateX(50%);
    display: inline-block;
    width: 8px;
    height: 90px;
    background-image: url(../@assets/line04.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

/* 매칭 내용 */
.matchingInner {
  position: relative;
  padding: 0 0 138px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 90px;

  .textInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .matchingBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .contentsInner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

    }
    .order {
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      height: 34px;
      padding: 0 18px;
      border-radius: 17px;
      background: $C_JOBDA_BLACK;
      box-shadow: 32px 32px 64px 0px rgba(0, 0, 0, 0.06);
      @include fontStyle($B4_M, $C_WHITE);

      .step {
        color: $C_LIGHT_GREEN_100;
        margin-right: 10px;
      }
    }

    .contents {
      margin: 24px 0 40px;
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      text-align: center;
      color: $C_COOL_GRAY_80;
    }

    .subText {
      margin-top: 28px;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      color: $C_COOL_GRAY_70;
    }

    .mainTitle {
      margin-top: 16px;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
    }

    &.firstBox {
      &::after {
        content: '';
        display: inline-block;
        width: 328px;
        height: 230px;
        background-image: url(../@assets/matching3.png);
        background-size: contain;
        background-position: center;

        background-repeat: no-repeat;
      }
    }

    &.secondBox {
      margin-top: 32px;

      &::after {
        content: '';
        display: inline-block;
        width: 328px;
        height: 230px;
        background-image: url(../@assets/matching4.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }

      &::before {
        content: '';
        position: absolute;
        bottom: 725px;
        right: 50%;
        transform: translateX(50%);
        display: inline-block;
        width: 8px;
        height: 90px;
        background-image: url(../@assets/line04.png);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    .btnBasic {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 328px;
      margin-bottom: 32px;
      padding: 14px 22px;
      border-radius: 4px;
      border: 1px solid $C_JOBDA_BLACK;
      @include fontStyle($B3_B, $C_JOBDA_BLACK);
    }
  }
}
