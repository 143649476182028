@import '../commonStyle.module.scss';

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;

  .title {
    padding: 16px 0;
    width: 100%;
    height: 88px;
    background-color: #4021ff;
    font-family: $F_RIX;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.24px;
    color: $C_WHITE;
    white-space: pre-wrap;
  }

  .emphasis {
    color: #00ff9d;
  }
}
