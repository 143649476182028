
.tooltipArea {
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 6px 8px;
  border-radius: 4px;
  white-space: nowrap;
  
  &.black {
    background-color: #121619;
    color: #fff;
  }

  &.green {
    background-color: #52c564;
    color: #fff;
  }

  &.blue {
    background-color: #2b53ae;
    color: #fff;
  }

  .text {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    font-family: 'Pretendard', sans-serif;
  }

  &::after {
    position: absolute;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    content: '';
  }
}
