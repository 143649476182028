@import '../matchIntroduce.module.scss';

.stepArea {
  width: 100%;

  .stepInner {
    display: flex;
    height: 400vh;
    margin: 0 auto;
  }
}

.commonBox {
  padding-top: 0;
  width: 100%;
  background-color: #121619;

  &.fadeInFirst {
    background-color: $C_JOBDA_WHITE;
  }
}

.screenArea {
  position: sticky;
  top: 0;
  width: 100%;
  height: 100vh;

  .scrollStep {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateY(-50%);
    transition: opacity 0.4s ease-in-out;
    opacity: 0;
    background-color: $C_JOBDA_BLACK;

    &.visible {
      opacity: 1;
      z-index: 1;
    }

    &.introInner {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      background: $C_JOBDA_BLACK;

      .mainText {
        text-align: center;
        font-size: 34px;
        font-weight: 700;
        line-height: 44px;
        color: $C_WHITE;

        .import {
          color: linear-gradient($C_POSITIVE 0%, $C_SKY_100 100%);
        }
      }
    }

    &.bgDissolve {
      background: linear-gradient(166deg, #6cdd83 0%, #7adeda 100%);

      .mainText {
        color: $C_JOBDA_BLACK;
      }

      .logo {
        width: 268px;
        height: 28px;
        background-image: url(../@assets/logo.png);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}

.textEffect {
  display: block;
  margin-top: 4px;
  background: linear-gradient(166deg, #6cdd83 0%, #7adeda 100%);
  background-clip: text;
  color: transparent;
}

/* 스크롤 */
.scroll {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  color: $C_WHITE;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(80px + env(safe-area-inset-bottom));
  @include fontStyle($B1_B, $C_COOL_GRAY_60);
  z-index: 2;

  svg {
    transform: translateY(0);
    animation: moveDown 2s ease-in-out infinite alternate;
  }
}

@keyframes moveDown {
  to {
    transform: translateY(5px);
  }
}

.fadeInFirst {
  .scroll {
    opacity: 0;
  }
}
