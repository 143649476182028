@import '../../styles/base.scss';
@import '../../styles/common.scss';
@import '../../styles/font.scss';
@import '../../styles/mixins.scss';
@import '../../styles/reset.scss';

$COMPONENT_IMG_PATH: '@assets';

.contentInner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 80px 0;

  &.bottomBox {
    padding: 80px 0 0 0;
  }

  &.bgDiff {
    background-color: $C_COOL_GRAY_30;
  }
}

/* 공통 폰트 */
.basicTitle {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .subTitle {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.18px;
    color: $C_JOBDA_BLACK;
  }

  .mainTitle {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.28px;
    color: $C_JOBDA_BLACK;

    .import {
      color: $C_GREEN_150;
    }
  }
}

/* 별 */
.star {
  width: 20px;
  height: 20px;
  background-image: url($COMPONENT_IMG_PATH + '/partDot.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &.starWhite {
    background-image: url($COMPONENT_IMG_PATH + '/partDot_w.png');
  }
}

/* 공통 버튼 */
.btnBasic {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.14px;
  color: $C_JOBDA_BLACK;

  .btnIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: $C_JOBDA_BLACK;
  }

  &:disabled {
    color: $C_COOL_GRAY_60;

    .btnIcon {
      background-color: $C_COOL_GRAY_50 !important;
    }
  }
}

.btnClick {
  display: flex;
  height: 46px;
  padding: 11px 16px 11px 22px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.14px;
  color: $C_JOBDA_BLACK;

  &.btnBlack {
    background-color: $C_JOBDA_BLACK;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.14px;
    color: $C_WHITE;
  }

  &.btnWhite {
    background-color: $C_WHITE;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.14px;
    color: $C_JOBDA_BLACK;

    &:disabled {
      background-color: $C_COOL_GRAY_80;
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: -0.14px;
      color: $C_COOL_GRAY_70;
    }
  }

  &.btnGreen {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 12px 12px 15px 32px;
    width: 272px;
    height: 64px;
    background-color: $C_GREEN_100;
    gap: 16px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.18px;
    border-radius: 0;
    color: $C_JOBDA_BLACK;

    &::after {
      content: '';
      position: absolute;
      left: 1px;
      bottom: 2px;
      width: 270px;
      height: 7px;
      background-color: $C_JOBDA_BLACK;
    }
  }
}

/* 이미지 슬라이드 공통 스타일 */
.slideSubText {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .icon {
    width: 64px;
    height: 64px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &.folder {
      background-image: url($COMPONENT_IMG_PATH + '/icon_file_green.png');
    }

    &.target {
      background-image: url($COMPONENT_IMG_PATH + '/icon_target_blue.png');
    }
  }

  .text {
    margin-top: 12px;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.18px;
    color: $C_JOBDA_BLACK;
  }

  .desc {
    position: relative;
    margin-top: 5px;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.18px;
    color: $C_GREEN_200;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $C_GREEN_200;
    }
  }

  .star {
    margin: 24px 0;
    width: 20px;
    height: 20px;
  }
}

.slideInner {
  :global {
    .swiper-container {
      overflow: initial !important;
      overflow-x: clip !important;
    }

    .pagination-bullets {
      position: absolute;
      top: -60px;
      margin: 0 !important;
      padding: 16px 0 0 0 !important;
    }

    .swiper-pagination-bullet {
      width: 6px !important;
      height: 6px !important;
      background: $C_COOL_GRAY_60 !important;
    }

    .swiper-pagination-bullet-active {
      width: 18px !important;
      background: $C_COOL_GRAY_60 !important;
    }
  }
}

.slideDark {
  .text {
    color: $C_WHITE;
  }

  .basicTitle {
    .subTitle,
    .mainTitle {
      color: $C_WHITE;
    }
  }
}

/* 말풍선 */
.balloon {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    right: -4.5px;
    bottom: 0px;
    width: 18px;
    height: 20px;
    background-image: url($COMPONENT_IMG_PATH + '/saying.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.balloonSmall {
  position: relative;
  display: flex;
  padding: 10px 16px;
  width: fit-content;
  background: $C_JOBDA_BLACK;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: -0.12px;
  color: $C_WHITE;

  &::after {
    content: '';
    position: absolute;
    right: -4.5px;
    bottom: 0px;
    width: 18px;
    height: 20px;
    background-image: url($COMPONENT_IMG_PATH + '/saying.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}
