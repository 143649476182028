// 최소 화면 사이즈
$MIN_WIDTH: 1168px;
// 글로벌 header height
$GLOBAL_HEADER_HEIGHT: 54px;
// lnb height
$NAV_HEIGHT: 48px;
$HEADER_SEARCH_AREA_HEIGHT: 70px;
$HEADER_SEARCH_AREA_MAX_HEIGHT: 82px;
$HEADER_MENU_AREA_HEIGHT: 48px;
$HEADER_HEIGHT: $HEADER_SEARCH_AREA_HEIGHT + $HEADER_MENU_AREA_HEIGHT;
$CONTENTS_WIDTH: 1120px;
$FOOTER_SITEMAP_HEIGHT: 339px;
$FOOTER_CONTENT_HEIGHT: 106px;
$FOOTER_COPYRIGHT_HEIGHT: 46px;
$FOOTER_HEIGHT: $FOOTER_SITEMAP_HEIGHT + $FOOTER_CONTENT_HEIGHT +
  $FOOTER_COPYRIGHT_HEIGHT;

// 공통 컴포넌트 사이즈
$TOP_NUDGE_HEIGHT: 48px;

// font
$F_NOTO_SANS_KR: 'Noto Sans KR', sans-serif;
$F_WEMAKE_PRICE: 'Wemakeprice', sans-serif;
$F_PRETENDARD: Pretendard, sans-serif;
$F_RIX: RixYeoljeongdo_Regular, sans-serif;

// z-index
$Z_INDEX_BASIC: 10;
$Z_INDEX_FIXED: 100;
