@import '../../matchIntroduce.module.scss';
$COMPONENT_IMG_PATH: $STATIC_IMG_PATH + '/v2/components/accaIntroduce';

.curatorList {
  margin: 64px 0;
  width: 100%;
  overflow: hidden;

  .flowImg {
    animation-duration: 60s;
    animation-iteration-count: infinite;
    animation-name: flow-ani;
    animation-timing-function: linear;
    background-position: 0 0;
    background-repeat: repeat-x;
    height: 246px;

    &:first-child {
      background-image: url(../../@assets/flow.png);
      background-size: 1440px 246px;
      width: 2880px;
    }

    @keyframes flow-ani {
      0% {
        background-position: 2880px 0;
      }
      100% {
        background-position: 0 0;
      }
    }
  }
}
