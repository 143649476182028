@import '../commonStyle.module.scss';

.desc {
  margin-top: 40px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.12px;
  color: $C_JOBDA_BLACK;

  .descImport {
    font-weight: 700;
  }
}

/* 비로그인 상태 */
.loginBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  padding: 32px 24px;
  gap: 24px;
  width: 328px;
  background-color: $C_WHITE;
  border-radius: 16px;

  .loginText {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.2px;
    color: $C_JOBDA_BLACK;
  }
}

/* 이름과 상태 */
.nameState {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  padding: 10px 12px;
  background-color: $C_JOBDA_BLACK;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.14px;
  color: #fff;

  .import {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100px;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.14px;
    color: $C_GREEN_100;
  }
}

/* 프로필 역량검사 응시 상태 */
.stateInner {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  gap: 40px;
  margin-top: 56px;

  .stateBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    background-color: #fff;
    padding: 32px 24px;
    border-radius: 16px;
    box-shadow: 24px 32px 40px 0px rgba(0, 0, 0, 0.06);
    &.missionOk {
      position: relative;
      border: 2px solid $C_GREEN_100;

      &::after {
        content: '';
        position: absolute;
        top: -32px;
        left: 20px;
        width: 64px;
        height: 64px;
        background-image: url(../@assets/yes.png);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    &.missionNo {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: -32px;
        left: 20px;
        width: 64px;
        height: 64px;
        background-image: url(../@assets/no.png);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}

.stateTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;

  .titleText {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.2px;
    color: $C_JOBDA_BLACK;

    .titleSub {
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: -0.2px;
      color: $C_JOBDA_BLACK;
    }
  }
}

/* 프로그래스 바 영역 */
.progressBox {
  width: 100%;
  height: 84px;

  .noProfile {
    width: 100%;
    height: 84px;
    background-image: url(../@assets/noTest.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

/* 응시 유무 */
.testBox {
  .testYes {
    width: 282px;
    height: 84px;
    background-image: url(../@assets/yesTest.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .testNo {
    width: 282px;
    height: 84px;
    background-image: url(../@assets/noTestAlert.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

/* 마지막 문구 */
.stateText {
  margin-top: 40px;
  text-align: left;
  width: 300px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.12px;
  color: $C_COOL_GRAY_70;
}
