@import '../commonStyle.module.scss';

.floatingBar {
  position: sticky;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  bottom: 0;
  padding-bottom: 52px;
  width: 100%;
  z-index: 9;

  .floatingInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    width: 100%;

    .floatingText {
      @include fontStyle($B1P_M, $C_JOBDA_BLACK);

      .count {
        @include fontStyle($B1_B, $C_JOBDA_BLACK);
      }
    }

    .floatingBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 312px;
      height: 48px;
      padding: 14px 0;
      border-radius: 4px;
      background: $C_WHITE;
      box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.5);
      font-size: 16px;
      font-weight: 700;
      color: $C_JOBDA_BLACK;

      &:hover {
        background: $C_COOL_GRAY_30;
      }

      svg {
        margin-left: 8px;

        path {
          fill: $C_JOBDA_BLACK;
        }
      }

      &.btnDownload {
        border: none;
        background: linear-gradient(90deg, #6cdd83 0%, #4bcdd6 100%);

        &:hover {
          background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.1) 0%,
              rgba(0, 0, 0, 0.1) 100%
            ),
            linear-gradient(90deg, #6cdd83 0%, #4bcdd6 100%);
        }
      }

      &:disabled {
        position: relative;
        background: $C_COOL_GRAY_50;
        color: $C_COOL_GRAY_60;

        svg {
          path {
            fill: $C_COOL_GRAY_60;
          }
        }

        &:hover {
          background: $C_COOL_GRAY_50;
        }

        &::before {
          position: absolute;
          top: 28px;
          content: '';
          display: block;
          width: 232px;
          height: 70px;
          background: url(../@assets/tooltip.png);
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
  }
}
