@import '../commonStyle.module.scss';

.wrap {
  padding-bottom: 56px;
}

// detail 공통 영역
.detailInfoInner {
  display: flex;
  flex-direction: column;
  gap: 56px;

  &:after {
    flex: none;
    width: 100%;
    background-size: cover;
    content: '';
  }
}

.detailInfoTitle {
  font-family: $F_RIX;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  color: $C_WHITE;
}

.detailInfoDesc {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  color: $C_COOL_GRAY_60;
}

// questionSlider에 좌우 간격주는 요소
.sliderItemWrap {
  padding: 0 12px;
}
