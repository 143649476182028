
.editor * {
    margin: 0;
    padding: 0;
    border: 0;
    text-align: left;
    font-size: 100%;
    font-family: Pretendard, "Noto Sans KR", sans-serif;
    vertical-align: baseline;
    word-break: break-all;
    line-height: 22px;
  }
  
  /* --- RESET CSS END --- */
  
  .editor > * {
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #121619;
    padding: 6px 0;
  }
  
  .editor .placeholder {
    color: #ced4da;
    opacity: 1 !important;
  }
  
  .editor h1 {
    margin-top: 34px;
    padding: 10px 0;
    font-weight: 700;
    font-size: 22px;
    line-height: 24px;
    color: #121619;
  }
  
  .editor h1:first-child {
    margin-top: 0;
  }
  
  /*deprecated*/
     .editor h2 {
       margin: 24px 0 16px;
       font-weight: 700;
       font-size: 18px;
       line-height: 30px;
       color: #121619;
     }
  
.editor .t1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #343A40;
  letter-spacing: -0.01em;
  margin: 0;
  padding: 0;
}

.editor .t2 {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #343A40;
  letter-spacing: -0.01em;
  margin: 0;
  padding: 0;
}

.editor .t3 {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #343A40;
  letter-spacing: -0.01em;
  margin: 0;
  padding: 0;
}

  .editor ul,
  .editor ol {
    list-style: none;
    padding-left: 0;
  }
  
  .editor ol {
    counter-reset: list;
  }
  
  .editor ol > li {
    display: flex;
  }
  
  .editor ul > li {
    display: flex;
  }
  
  .editor ul > li::before {
    flex-shrink: 0;
    content: '';
    width: 12px;
    height: 22px;
    background: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.44358 10.0088L0.75 6.31527L1.73341 5.33186L4.47456 8.07301L10.2976 2.25L11.25 3.20243L4.44358 10.0088Z' fill='%23121619' /%3E%3C/svg%3E") no-repeat 50%;
    margin-right: 10px;
    display: block;
  }
  
  .editor ol > li:last-child,
  .editor ul > li:last-child {
    padding-bottom: 0;
  }
  
  .editor ol > li::before {
    flex-shrink: 0;
    counter-increment: list;
    content: counters(list, "-") ". ";
    display: inline-block;
  }
  
  .editor div{
    line-height:22px;
  }

  .editor strong {
    padding: 2px 4px;
    background: #f4f4f5;
    font-weight: unset;
  }
  
  .editor b{
    font-weight: bold;
  }
  
  .editor a {
    color: #121619;
    text-decoration: underline;
    cursor: pointer;
  }
  .editor .image-block {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .editor .image-block img {
      
  }
  
  .editor .embed {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    
  }
  
  @media (max-width: 720px) {
    .editor > * {
      font-size: 13px;
      line-height: 22px;
    }
    .editor h1 {
      font-size: 18px;
      margin-top: 16px;
    }
  
    .editor h2 {
      font-size: 17px;
    }
    
  .editor .t1 {
    font-size: 18px;
    line-height: 24px;
  }

  .editor .t2 {
    font-size: 14px;
    line-height: 22px;
  }

  .editor .t3 {
    font-size: 12px;
    line-height: 16px;
  }
  }