@import '../commonStyle.module.scss';

// 합격자 이력서 모음
.basicTitle {
  margin-bottom: 80px;
}

.sliderItem {
  width: 360px;
  height: 358px;

  @for $i from 1 through 2 {
    &.type#{$i} {
      background-image: url(../@assets/resume#{$i}.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center bottom;
    }
  }
}

.slideInner {
  width: 360px;
  height: 358px;
}
