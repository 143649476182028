@import '../commonStyle.module.scss';

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 56px 0;
  background-color: initial;
  background-image: url(../@assets/bgJobdaMatchBanner.png);
  background-size: cover;
  background-position: center;

  // 잡다매치 배너 이미지
  &:after {
    flex: none;
    width: 328px;
    height: 328px;
    border-radius: 10px;
    background-image: url(../@assets/m_sec6_img1.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    content: '';
  }
}
