@import '../accaIntro.module.scss';

.logoList {
  display: flex;
  justify-content: center;
  margin: 40px 0;
  width: 100%;

  .logo {
    width: 304px;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

.companyList {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
