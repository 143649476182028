// 디자인 시스템 적용
$C_JOBDA_BLACK: #121619;
$C_JOBDA_WHITE: #f9f9fa;
$C_WHITE: #fff;
$C_BLACK: #000;
$C_ERROR: #fb4e4e;
$C_GUIDELINE: #00c8ff;
$C_DIMMED: rgba($C_BLACK, 0.6);

// JOBDA_COOL_GRAY
$C_COOL_GRAY_30: #f4f4f5;
$C_COOL_GRAY_40: #e7e8ea;
$C_COOL_GRAY_50: #cfd1d5;
$C_COOL_GRAY_60: #9fa4ab;
$C_COOL_GRAY_70: #656a71;
$C_COOL_GRAY_80: #44474b;
$C_COOL_GRAY_90: #25282b;

// JOBDA_GREEN
$C_GREEN_30: #effdf3;
$C_GREEN_70: #b3eec1;
$C_GREEN_90: #92e6a3;
$C_GREEN_100: #6cdd83;
$C_GREEN_150: #4bd667;
$C_GREEN_200: #52c564;

// JOBDA_YELLOW
$C_YELLOW_30: #fffef2;
$C_YELLOW_100: #fcf16c;
$C_YELLOW_300: #d8ca2c;

// JOBDA_SKY
$C_SKY_30: #f3fdfc;
$C_SKY_100: #7adeda;
$C_SKY_300: #46b2af;

// JOBDA_BLUE
$C_BLUE_30: #e1e7f8;
$C_BLUE_80: #5878cf;
$C_BLUE_90: #315fc7;
$C_BLUE_100: #2b53ae;

// JOBDA_LIGHT
$C_LIGHT_GREEN_100: #75e78c;
$C_LIGHT_BLUE_100: #afc6ff;

// RED
$C_RED_800: #e03131;

// 기존 컬러
// GREEN
$C_GREEN_40: #e0f9e4;
$C_GREEN_50: #d0f5da;
$C_GREEN_60: #c3f2cd;
$C_GREEN_80: #a2ebb4;
$C_GREEN_300: #41ac4d;
$C_GREEN_400: #3c8e42;
$C_GREEN_500: #377538;

// YELLOW
$C_YELLOW_40: #fffde5;
$C_YELLOW_50: #fefcd8;
$C_YELLOW_60: #fefccb;
$C_YELLOW_70: #fef9bd;
$C_YELLOW_80: #fef8b1;
$C_YELLOW_90: #fdf69e;
$C_YELLOW_200: #e7da4b;
$C_YELLOW_400: #b3a82a;
$C_YELLOW_500: #938a29;

// SKY
$C_SKY_40: #e9f9f9;
$C_SKY_50: #dcf7f6;
$C_SKY_60: #d1f5f3;
$C_SKY_70: #c5f1ef;
$C_SKY_80: #bbefed;
$C_SKY_90: #afecea;
$C_SKY_200: #5ec7c3;
$C_SKY_400: #409390;
$C_SKY_500: #3a7977;

// LIME
$C_LIME_30: #f9ffeb;
$C_LIME_40: #f3fedf;
$C_LIME_50: #edfdd2;
$C_LIME_60: #e7fcc6;
$C_LIME_70: #e2fbb9;
$C_LIME_80: #dcfaad;
$C_LIME_90: #d0f894;
$C_LIME_100: #bfef7b;
$C_LIME_200: #a4d95a;
$C_LIME_300: #8ec93d;
$C_LIME_400: #79a837;
$C_LIME_500: #668a33;

// PURPLE
$C_PURPLE_30: #f5f2fd;
$C_PURPLE_40: #f2edfc;
$C_PURPLE_50: #eee7fc;
$C_PURPLE_60: #e8e0fb;
$C_PURPLE_70: #e0d5f9;
$C_PURPLE_80: #d5c7f7;
$C_PURPLE_90: #c7b4f4;
$C_PURPLE_100: #b49bf0;
$C_PURPLE_200: #9375db;
$C_PURPLE_300: #7753ca;
$C_PURPLE_400: #623fb3;
$C_PURPLE_500: #553b94;

// BLUE
$C_BLUE_40: #c3d1ef;
$C_BLUE_50: #a7b9e7;
$C_BLUE_60: #8ba3df;
$C_BLUE_70: #718dd7;
$C_BLUE_200: #304a89;
$C_BLUE_300: #2c4170;
$C_BLUE_400: #29385d;
$C_BLUE_500: #25314d;

// GRAY
$C_WARM_GRAY_30: #f4f4f4;
$C_WARM_GRAY_40: #e8e8e8;
$C_WARM_GRAY_50: #d1d1d1;
$C_WARM_GRAY_60: #a4a4a4;
$C_WARM_GRAY_70: #6a6a6a;
$C_WARM_GRAY_80: #474747;
$C_WARM_GRAY_90: #232323;

// WARNING
$C_WARNING: #f6a609;
$C_WARNING_DARK: #e89806;
$C_WARNING_LIGHT: #ffbc1f;
$C_WARNING_RED_DARK: #e93c3c;
$C_WARNING_RED_LIGHT: #ff6262;
$C_WARNING_RED_WHITE: #f9e0e2;

// POSITIVE
$C_POSITIVE: #6cdd83;
$C_POSITIVE_DARK: #3ed160;
$C_POSITIVE_LIGHT: #92e6a3;

// ERROR
$C_ERROR_LIGHT: #e36a6a;

