@import '../../styles/base.scss';
@import '../../styles/common.scss';
@import '../../styles/font.scss';
@import '../../styles/mixins.scss';
@import '../../styles/reset.scss';

$COMPONENT_IMG_PATH: '@assets';

/* 모바일 폰트 */
.body {
  font-family: $F_PRETENDARD !important;
  background-color: #f8f9fa;
}

// 전체 감싸는 요소
.wrap {
  padding: 0 16px;
  background-color: #f8f9fa;
  text-align: center;

  // 구름 배경이 깔리는 경우
  &.bg {
    position: relative;

    &:before {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 706px;
      background-image: url($COMPONENT_IMG_PATH + '/bgCloud.png');
      background-size: cover;
      background-position: center;
      content: '';
    }
  }
}

// 내부 inner 영역
.inner {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 328px;
  margin: 0 auto;
  padding-top: 56px;
}

.sectionTitleArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  // 공통 섹션 타이틀
  .sectionTitle {
    width: 328px;
    padding: 12px 0;
    background-image: linear-gradient(
      90deg,
      rgba($C_GREEN_100, 0) 0%,
      $C_GREEN_100 50.52%,
      rgba($C_GREEN_100, 0) 100%
    );
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.2px;
    color: $C_JOBDA_WHITE;
    text-align: center;
  }

  // 공통 섹션 desc
  .sectionDesc {
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    color: $C_COOL_GRAY_80;
  }
}

// 공통 버튼
.btnEvent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 280px;
  height: 48px;
  margin: 0 auto;
  border-radius: 4px;
  background-color: $C_JOBDA_BLACK;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: $C_JOBDA_WHITE;

  // 선 타입 버튼
  &.outLine {
    border: 1px solid $C_JOBDA_BLACK;
    background-color: $C_WHITE;
    color: $C_JOBDA_BLACK;
  }
}

//이벤트 응모 버튼
.btnApply {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 280px;
  height: 48px;
  margin: 0 auto;
  border-radius: 4px;
  background-color: $C_JOBDA_BLACK;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: $C_JOBDA_WHITE;

  &.disabled {
    background-color: $C_COOL_GRAY_40;
    color: $C_COOL_GRAY_60;
    opacity: 0.64;
  }
  // 선 타입 버튼
  &.outLine {
    border: 1px solid $C_JOBDA_BLACK;
    background-color: $C_WHITE;
    color: $C_JOBDA_BLACK;
  }
}

// 미션 영역
.missionArea {
  border: 1px solid $C_COOL_GRAY_40;
  border-radius: 16px;
  background-color: $C_WHITE;

  .missionInner {
    border-radius: 16px 16px 0 0;
    background-color: #f1f3f5;
  }

  // 이미지 슬라이드 영역
  .sliderItem {
    width: 328px;
    height: 328px;
    border-radius: 16px 16px 0 0;
    background-color: $C_COOL_GRAY_30;
    background-size: cover;
    vertical-align: top;
  }

  .btnArea {
    display: flex;
    justify-content: center;
    padding: 24px;
  }
}

//슬라이드
.missionInner {
  :global {
    .pagination-bullets {
      margin: 0 !important;
      padding: 16px 0 0 0 !important;
      background-color: $C_WHITE;
    }

    .swiper-pagination-bullet {
      width: 6px !important;
      height: 6px !important;
      background: $C_COOL_GRAY_60 !important;
    }

    .swiper-pagination-bullet-active {
      width: 18px !important;
      background: $C_COOL_GRAY_60 !important;
    }
  }
}

// 혜택 영역
.benefitArea {
  display: flex;
  flex-direction: column;
  border: 1px solid $C_COOL_GRAY_40;
  border-radius: 16px;
  background-color: $C_WHITE;
  text-align: left;

  .benefitInner {
    display: flex;
    flex-direction: column;
    width: 100%;

    & + .benefitInner {
      border-left: 1px solid $C_COOL_GRAY_40;
    }

    .benefitWrap {
      & + .benefitWrap {
        border-top: 1px solid $C_COOL_GRAY_40;
        border-left: 0;
      }
    }
  }

  .benefitWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 32px;

    &:after {
      flex: none;
      width: 100%;
      height: 194px;
      background-color: $C_COOL_GRAY_30;
      background-size: cover;
      content: '';
    }

    & + .benefitWrap {
      border-left: 1px solid $C_COOL_GRAY_40;
    }

    .textArea {
      text-align: center;
      margin-bottom: 32px;
    }

    .benefitTitle {
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
      letter-spacing: -0.28px;
      text-align: center;

      .lightText {
        font-weight: 400;
      }
    }

    .benefitDesc {
      margin-top: 8px;
      font-size: 16px;
      font-weight: 700;
      line-height: 26px;
      text-align: center;
      color: $C_COOL_GRAY_70;
    }
  }
}

//응모하기 버튼
.btnEventArea {
  padding: 24px;
}

// 알림 텍스트 영역
.notice {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.14px;
  text-align: left;
  color: #656a71;
}
