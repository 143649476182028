@import '../../../../styles/common.scss';

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
&.Fill{
  &:disabled:not(.isLoading) {
    background-color: $color_background_disabled !important;
    color: $color_text_disabled !important;

    &:hover {
      background-color: $color_background_disabled !important;
    }

    &:active {
      background-color: $color_background_disabled !important;
    }
  }
}
  // fill에 border-color가 추가 되어야함.
  // 일단 하나의 타입에 대해서만 적용되어있음.
  &.OutLine {
    @include V2FillStyle($color_fill_neutral_subtle_default);

    &:disabled {
      color: $color_text_disabled !important;
      background-color: unset !important;

      &:hover {
        background-color: unset !important;
      }
    }
  }

  &.Text {
    &:not(:hover):not(:active) {
      background-color: unset !important; // hover, active가 아닐 때 배경 제거
    }

    &:disabled {
      color: $color_text_disabled !important;

      &:hover {
        background-color: unset !important;
      }
    }
  }

  .inner {
    display: flex;
    align-items: center;
  }

  &.fill_brand_default {
    background-color: $V2C_GREEN_25;
    &:hover {
      background-color: $V2C_GREEN_50;
    }
    &:active {
      background-color: $V2C_GREEN_100;
    }
  }
  &.fill_brand_subtle_default {
    background-color: $V2C_GRAY_WHITE;
    &:hover {
      background-color: $V2C_GREEN_25;
    }
    &:active {
      background-color: $V2C_GREEN_50;
    }
  }
  &.fill_brand_strong_default {
    background-color: $V2C_GREEN_500;
    &:hover {
      background-color: $V2C_GREEN_600;
    }
    &:active {
      background-color: $V2C_GREEN_700;
    }
  }
  &.fill_neutral_default {
    background-color: $V2C_GRAY_50;
    &:hover {
      background-color: $V2C_GRAY_100;
    }
    &:active {
      background-color: $V2C_GRAY_200;
    }
  }
  &.fill_neutral_subtle_default {
    background-color: rgba(51, 52, 54, 0);
    &:hover {
      background-color: rgba(51, 52, 54, 0.05);
    }
    &:active {
      background-color: rgba(51, 52, 54, 0.08);
    }
  }
  &.fill_neutral_strong_default {
    background-color: $V2C_GRAY_500;
    &:hover {
      background-color: $V2C_GRAY_600;
    }
    &:active {
      background-color: $V2C_GRAY_700;
    }
  }
  &.fill_success_default {
    background-color: $V2C_GREEN_25;
    &:hover {
      background-color: $V2C_GREEN_50;
    }
    &:active {
      background-color: $V2C_GREEN_100;
    }
  }
  &.fill_success_strong_default {
    background-color: $V2C_GREEN_500;
    &:hover {
      background-color: $V2C_GREEN_600;
    }
    &:active {
      background-color: $V2C_GREEN_700;
    }
  }
  &.fill_danger_default {
    background-color: $V2C_RED_25;
    &:hover {
      background-color: $V2C_RED_50;
    }
    &:active {
      background-color: $V2C_RED_100;
    }
  }
  &.fill_danger_strong_default {
    background-color: $V2C_RED_500;
    &:hover {
      background-color: $V2C_RED_600;
    }
    &:active {
      background-color: $V2C_RED_700;
    }
  }
  &.fill_warning_default {
    background-color: $V2C_YELLOW_25;
    &:hover {
      background-color: $V2C_YELLOW_50;
    }
    &:active {
      background-color: $V2C_YELLOW_100;
    }
  }
  &.fill_warning_strong_default {
    background-color: $V2C_YELLOW_500;
    &:hover {
      background-color: $V2C_YELLOW_600;
    }
    &:active {
      background-color: $V2C_YELLOW_700;
    }
  }
  &.fill_information_default {
    background-color: $V2C_SKYBLUE_25;
    &:hover {
      background-color: $V2C_SKYBLUE_50;
    }
    &:active {
      background-color: $V2C_SKYBLUE_100;
    }
  }
  &.fill_information_strong_default {
    background-color: $V2C_SKYBLUE_500;
    &:hover {
      background-color: $V2C_SKYBLUE_600;
    }
    &:active {
      background-color: $V2C_SKYBLUE_700;
    }
  }
  &.fill_accent_default {
    background-color: $V2C_GREEN_25;
    &:hover {
      background-color: $V2C_GREEN_50;
    }
    &:active {
      background-color: $V2C_GREEN_100;
    }
  }
  &.fill_accent_green_default {
    background-color: $V2C_GREEN_25;
    &:hover {
      background-color: $V2C_GREEN_50;
    }
    &:active {
      background-color: $V2C_GREEN_100;
    }
  }
  &.fill_accent_green_strong_default {
    background-color: $V2C_GREEN_500;
    &:hover {
      background-color: $V2C_GREEN_600;
    }
    &:active {
      background-color: $V2C_GREEN_700;
    }
  }
  &.fill_accent_springgreen_default {
    background-color: $V2C_SPRINGGREEN_25;
    &:hover {
      background-color: $V2C_SPRINGGREEN_50;
    }
    &:active {
      background-color: $V2C_SPRINGGREEN_100;
    }
  }
  &.fill_accent_springgreen_strong_default {
    background-color: $V2C_SPRINGGREEN_500;
    &:hover {
      background-color: $V2C_SPRINGGREEN_600;
    }
    &:active {
      background-color: $V2C_SPRINGGREEN_700;
    }
  }
  &.fill_accent_olivegreen_default {
    background-color: $V2C_OLIVEGREEN_25;
    &:hover {
      background-color: $V2C_OLIVEGREEN_50;
    }
    &:active {
      background-color: $V2C_OLIVEGREEN_100;
    }
  }
  &.fill_accent_olivegreen_strong_default {
    background-color: $V2C_OLIVEGREEN_500;
    &:hover {
      background-color: $V2C_OLIVEGREEN_600;
    }
    &:active {
      background-color: $V2C_OLIVEGREEN_700;
    }
  }
  &.fill_accent_yellow_default {
    background-color: $V2C_YELLOW_25;
    &:hover {
      background-color: $V2C_YELLOW_50;
    }
    &:active {
      background-color: $V2C_YELLOW_100;
    }
  }
  &.fill_accent_yellow_strong_default {
    background-color: $V2C_YELLOW_500;
    &:hover {
      background-color: $V2C_YELLOW_600;
    }
    &:active {
      background-color: $V2C_YELLOW_700;
    }
  }
  &.fill_accent_gold_default {
    background-color: $V2C_GOLD_25;
    &:hover {
      background-color: $V2C_GOLD_50;
    }
    &:active {
      background-color: $V2C_GOLD_100;
    }
  }
  &.fill_accent_gold_strong_default {
    background-color: $V2C_GOLD_500;
    &:hover {
      background-color: $V2C_GOLD_600;
    }
    &:active {
      background-color: $V2C_GOLD_700;
    }
  }
  &.fill_accent_orange_default {
    background-color: $V2C_ORANGE_25;
    &:hover {
      background-color: $V2C_ORANGE_50;
    }
    &:active {
      background-color: $V2C_ORANGE_100;
    }
  }
  &.fill_accent_orange_strong_default {
    background-color: $V2C_ORANGE_500;
    &:hover {
      background-color: $V2C_ORANGE_600;
    }
    &:active {
      background-color: $V2C_ORANGE_700;
    }
  }
  &.fill_accent_red_default {
    background-color: $V2C_RED_25;
    &:hover {
      background-color: $V2C_RED_50;
    }
    &:active {
      background-color: $V2C_RED_100;
    }
  }
  &.fill_accent_red_strong_default {
    background-color: $V2C_RED_500;
    &:hover {
      background-color: $V2C_RED_600;
    }
    &:active {
      background-color: $V2C_RED_700;
    }
  }
  &.fill_accent_pink_default {
    background-color: $V2C_PINK_25;
    &:hover {
      background-color: $V2C_PINK_50;
    }
    &:active {
      background-color: $V2C_PINK_100;
    }
  }
  &.fill_accent_pink_strong_default {
    background-color: $V2C_PINK_500;
    &:hover {
      background-color: $V2C_PINK_600;
    }
    &:active {
      background-color: $V2C_PINK_700;
    }
  }
  &.fill_accent_purple_default {
    background-color: $V2C_PURPLE_25;
    &:hover {
      background-color: $V2C_PURPLE_50;
    }
    &:active {
      background-color: $V2C_PURPLE_100;
    }
  }
  &.fill_accent_purple_strong_default {
    background-color: $V2C_PURPLE_500;
    &:hover {
      background-color: $V2C_PURPLE_600;
    }
    &:active {
      background-color: $V2C_PURPLE_700;
    }
  }
  &.fill_accent_violet_default {
    background-color: $V2C_VIOLET_25;
    &:hover {
      background-color: $V2C_VIOLET_50;
    }
    &:active {
      background-color: $V2C_VIOLET_100;
    }
  }
  &.fill_accent_violet_strong_default {
    background-color: $V2C_VIOLET_500;
    &:hover {
      background-color: $V2C_VIOLET_600;
    }
    &:active {
      background-color: $V2C_VIOLET_700;
    }
  }
  &.fill_accent_cobaltblue_default {
    background-color: $V2C_COBALTBLUE_25;
    &:hover {
      background-color: $V2C_COBALTBLUE_50;
    }
    &:active {
      background-color: $V2C_COBALTBLUE_100;
    }
  }
  &.fill_accent_cobaltblue_strong_default {
    background-color: $V2C_COBALTBLUE_500;
    &:hover {
      background-color: $V2C_COBALTBLUE_600;
    }
    &:active {
      background-color: $V2C_COBALTBLUE_700;
    }
  }
  &.fill_accent_skyblue_default {
    background-color: $V2C_SKYBLUE_25;
    &:hover {
      background-color: $V2C_SKYBLUE_50;
    }
    &:active {
      background-color: $V2C_SKYBLUE_100;
    }
  }
  &.fill_accent_skyblue_strong_default {
    background-color: $V2C_SKYBLUE_500;
    &:hover {
      background-color: $V2C_SKYBLUE_600;
    }
    &:active {
      background-color: $V2C_SKYBLUE_700;
    }
  }
  &.fill_accent_cyan_default {
    background-color: $V2C_CYAN_25;
    &:hover {
      background-color: $V2C_CYAN_50;
    }
    &:active {
      background-color: $V2C_CYAN_100;
    }
  }
}
.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 50%;
  animation: button-anim 0.7s linear infinite;
  border-color: $C_WHITE;
  border-right-color: transparent !important;
}
 
@keyframes button-anim {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}