@import '../index.module.scss';

$FADEIN_DURATION: 0.5s;
$FAQ_COUNT: 5;
$TRANSITION_DELAY: 0.06s;

// FAQ 영역
.faqArea {
  @include Flex($dir: column, $align: center, $justify: center);
  padding: 120px $space_L;
  background-color: $V2C_GRAY_800;

  .faqInner {
    width: 100%;
    margin-top: $space_XXXXL;

    &.on {
      .title {
        transform: translateY(0);
        opacity: 1;
      }

      .link {
        transform: translateY(0);
        opacity: 1;
      }

      .listItem {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}

// FAQ 영역
.faqList {
  .listItem {
    border-radius: $radius_XL;
    background: $V2C_GRAY_700;
    transform: translateY(24px);
    transition: transform $FADEIN_DURATION ease-in-out, opacity $FADEIN_DURATION ease-in-out;

    & + .listItem {
      margin-top: 16px;
    }
  }

  @for $i from 1 through $FAQ_COUNT {
    .listItem#{$i} {
      transition-delay: #{($i + 1) * $TRANSITION_DELAY};
      // opacity: 0;
    }
  }
}

.listTitle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;

  &.on {
    &:after {
      width: 32px;
      height: 32px;
      background-image: url('../img/icon_arrow_bottom.svg');
      transform: rotate(180deg);
    }
  }

  &:after {
    flex: none;
    width: 32px;
    height: 32px;
    background-image: url('../img/icon_arrow_bottom.svg');
    content: '';
  }
}

.question {
  @include V2FontStyle($body_1_b, $V2C_GRAY_WHITE);
}

.answer {
  overflow: hidden;
  height: 0;
  padding: 0 48px;
  @include V2FontStyle($body_2_m, $V2C_GRAY_400);
  transition: all 0.2s ease-in-out;

  &.on {
    padding-bottom: 32px;

    @for $i from 1 through 4 {
      &.type#{$i} {
        height: calc(#{$i} * 28px + 30px + 4px);
      }
    }
  }
}
