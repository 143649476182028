@import '../index.module.scss';

.logoList {
  @include Flex($dir: column, $align: center, $justify: center);
  padding-bottom: 80px;
  background-color: $V2C_GRAY_25;
  text-align: center;
  overflow: hidden;

  .logoImg {
    width: 3680px;
    height: 160px;
    margin-top: $space_XXXXL;
    animation-duration: 70s;
    animation-iteration-count: infinite;
    animation-name: flow-ani;
    animation-timing-function: linear;
    background-position: 0 0;
    background-repeat: repeat-x;
    background-image: url('../img/logoFlow.png');
    background-size: contain;

    @keyframes flow-ani {
      0% {
        background-position: 0 0;
      }
      100% {
        background-position: 3680px 0;
      }
    }

    @keyframes rev-flow-ani {
      0% {
        background-position: 0 0;
      }
      100% {
        background-position: 3680px 0;
      }
    }
  }
}
