@import '../accaIntro.module.scss';

.storyInner {
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url(../@assets/mentBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  //커버배경
  .coverBg {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background: $C_JOBDA_BLACK;
  }

  .storyText {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 1;

    //폰트
    .storyTitle {
      text-align: center;
      transform: translateY(24px);
      transition: transform $FADEIN_DURATION ease-in-out,
        opacity $FADEIN_DURATION ease-in-out;
      opacity: 0;
      margin-bottom: 32px;
      font-size: 34px;
      line-height: 44px;
      letter-spacing: -0.34px;
      color: #fff;

      &.on {
        transition-delay: 0.3s;
        transform: translateY(0);
        opacity: 1;
      }
    }

    .storyMent {
      text-align: center;
      transform: translateY(24px);
      transition: transform $FADEIN_DURATION ease-in-out,
        opacity $FADEIN_DURATION ease-in-out;
      opacity: 0;
      @include fontStyle($H5_B, $C_WHITE);

      &:not(:last-of-type) {
        margin-bottom: 24px;
      }

      &.on {
        transition-delay: 1s;
        transform: translateY(0);
        opacity: 1;
      }

      &.fadeLast {
        &.on {
          transition-delay: 1.5s;
        }
      }
    }
  }
}
