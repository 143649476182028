@import '../commonStyle.module.scss';

.slideInner {
  margin-top: 40px;
  width: 312px;
  height: 231px;

  :global {
    .pagination-bullets {
      margin: 40px 0 !important;
    }
  }
}

.interViewItem {
  display: inline-block;
  width: 312px;
  height: 231px;
  background-size: contain;
  background-repeat: no-repeat;
}

@for $i from 1 through 4 {
  .item#{$i} {
    background-image: url(../@assets/interviewee#{$i}.png);
  }
}

.subText {
  margin-top: 24px;
  font-size: 14px;
  font-weight: 500;
  color: $C_COOL_GRAY_70;
}

.btnJoin {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  width: 312px;
  height: 64px;
  background-color: $C_JOBDA_BLACK;
  font-size: 18px;
  font-weight: 700;
  color: $C_WHITE;
  border-radius: 4px;
}
