@import '../commonStyle.module.scss';

.banner2Inner {
  background: $C_JOBDA_BLACK;

  .star {
    margin-bottom: 24px;
  }

  .basicTitle {
    .subTitle {
      color: $C_WHITE;
    }
    .mainTitle {
      color: $C_WHITE;
      line-height: 40px;
    }
  }

  .desc {
    margin-top: 24px;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.12px;
    color: $C_WHITE;
  }
}
