@import '../commonStyle.module.scss';

.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 672px;
  padding: 0;
  background-color: initial;
  background-image: url(../@assets/bgCatchphrase.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.titleArea {
  width: 100%;
  padding: 16px 0;
  background-image: linear-gradient(
    90deg,
    rgba($C_WHITE, 0) 0%,
    rgba($C_WHITE, 0.5) 51.56%,
    rgba($C_WHITE, 0) 100%
  );
}

.title {
  display: block;
  font-size: 32px;
  font-weight: 800;
  line-height: 44px;
  letter-spacing: -0.32px;
}

.desc {
  margin-top: 12px;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.2px;
}
