
@mixin datepickerCustom {
  :global .react-datepicker-popper {
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.06)) drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
    padding-top: 20px;
    background: none;
    z-index: 10006;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
  }

  :global .react-datepicker {
    width: 256px;
    border-radius: 8px;

    .react-datepicker__month-container {
      width: 100%;
      border-radius: 8px;
      background-color: #ffffff;

      /** Header **/
      .react-datepicker__header {
        width: 100%;

        .react-datepicker__day-names {
          display: flex;
          justify-content: center;
          padding: 16px 16px 0 16px;

          .react-datepicker__day-name {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            font-weight: 700;
            font-size: 11px;
            line-height: 14px;
            color:#656a71;
          }
        }
      } // end of react-datepicker__header

      /** Day **/
      .react-datepicker__month {
        width: 100%;
        padding: 4px 16px 16px;
        border-radius: 0 0 9px 9px;

        .react-datepicker__week {
          display: flex;

          .react-datepicker__day {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            margin: 4px;
            font-weight: 400;
            font-size: 11px;
            line-height: 14px;
            letter-spacing: -0.073px;
            color: #25282b;
            cursor: pointer;

            .sunday {
              color:  #fb4e4e;
            }

            &:hover {
              border-radius: 50%;
              background-color: #e7e8ea;
              font-weight: 500;
              color: #25282b;
            }
          }

          .react-datepicker__day--outside-month {
            opacity: 0.5;

            .day {
              color: #25282b !important;
            }
          }

          //.react-datepicker__day--today {
          //  border: 1px solid $C_COOL_GRAY_60;
          //  border-radius: 50%;
          //
          //  &:hover {
          //    border: 1px solid $C_COOL_GRAY_80;
          //    background: none;
          //    color: #25282b;
          //  }
          //}

          .react-datepicker__day--selected {
            color: #ffffff;
            font-weight: 500;
            font-size: 11px;
            line-height: 14px;
            border-radius: 50%;
            background-color: #121619;

            .day {
              color: #ffffff;
            }

            &:hover {
              color: #ffffff;
              background-color: #121619;
            }
          }

          .react-datepicker__day--keyboard-selected {
            border: none;
            background: none;
          }

          .react-datepicker__day--disabled {
            opacity: 0.5;
            pointer-events: none;
          }
        } // end of react-datepicker__week
      } // end of react-datepicker__month

      /** Month **/
      .react-datepicker__monthPicker {
        width: 100%;
        height: 228px;
        padding: 16px;
        border-radius: 0 0 9px 9px;
        background-color: #ffffff;
        cursor: pointer;

        .react-datepicker__month-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;

          ~ .react-datepicker__month-wrapper {
            margin-top: 16px;
          }

          .react-datepicker__month-text {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 27px;
            margin: 0;
            border-radius: 24px;
            font-weight: 500;
            font-size: 13px;
            line-height: 18px;
            color: #25282b;

            &:hover {
              background-color: #e7e8ea;
            }

            ~ .react-datepicker__month-text {
              margin-left: 6px;
            }
          }

          .react-datepicker__month-text--keyboard-selected {
            font-weight: 700;
            font-size: 13px;
            line-height: 18px;
            display: flex;
            color: #ffffff;
            background-color: #121619;

            &:hover {
              background-color: #121619;
            }
          }


        }

        .react-datepicker__month--disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    } // end of react-datepicker__month-container

    /** Year **/
    .react-datepicker__year--container {
      width: 100%;
      border-radius: 8px;
      background-color: #ffffff;

      .react-datepicker__year {
        width: 100%;
        height: 228px;
        border-radius: 0 0 9px 9px;

        .react-datepicker__year-wrapper {
          display: flex;
          align-items: flex-start;
          align-content: flex-start;
          justify-content: center;
          flex-wrap: wrap;
          width: 100%;
          height: 100%;
          padding: 4px 24px 0;
          .react-datepicker__year-text {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 64px;
            height: 30px;
            margin: 12px auto 0;
            border-radius: 24px;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #25282b;
            cursor: pointer;

            &:hover {
              background-color: #e7e8ea;
            }
          }

          .react-datepicker__year-text--selected {
            font-weight: 700;
            color: #ffffff;
            background-color: #121619;

            &:hover {
              color: #ffffff;
              background-color: #121619;
            }
          }
        }
      }

      .react-datepicker__year-text--disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    } // end of react-datepicker__year--container
  } // end of react-datepicker
}

.wrap {
  @include datepickerCustom;

  font-family: 'Pretendard', sans-serif;
  user-select: none;

}
