@import '../matchIntroduce.module.scss';

.stepArea {
  width: 100%;

  .stepInner {
    display: flex;
    height: 200vh;
    margin: 0 auto;
  }
}

.commonBox {
  padding-top: 0;
  width: 100%;
  background-color: $C_JOBDA_WHITE;
}

.screenArea {
  position: sticky;
  top: 0;
  width: 100%;
  height: 100vh;

  .scrollStep {
    position: absolute;
    top: 50%;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    transform: translateY(-50%);
    transition: opacity 0.4s ease-in-out;
    opacity: 0;
    background: linear-gradient(166deg, #6cdd83 0%, #7adeda 100%);

    &.visible {
      opacity: 1;
      z-index: 1;
    }

    .mainTitle {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 600px;
      background-image: url(../@assets/hookingBg.png);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    &.bgDissolve {
      background: $C_JOBDA_WHITE;

      .mainTitle {
        background-image: url(../@assets/hookingBg_light.png);
      }
    }
  }
}
