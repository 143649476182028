@import '../commonStyle.module.scss';

.subText {
  margin-top: 24px;
  font-size: 14px;
  font-weight: 500;
  color: $C_COOL_GRAY_70;
}
.contentInner {
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  &::before {
    content: '';
    display: block;
    margin: 0 auto 16px;
    width: 312px;
    height: 200px;
    background-image: url(../@assets/gift1.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .giftInner {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 25px auto 0;
    padding-left: 104px;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color: $C_JOBDA_BLACK;

    .import {
      font-weight: 700;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -20px;
      left: 0;
      width: 88px;
      height: 88px;
      background-image: url(../@assets/giftBadge.png);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .naverPay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    padding-top: 40px;

    .giftBox {
      display: block;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      color: $C_JOBDA_BLACK;

      strong {
        font-weight: 700;
      }

      &:first-child {
        &::before {
          content: '';
          display: block;
          margin-bottom: 16px;
          width: 312px;
          height: 218px;
          background-image: url(../@assets/gift2.png);
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
  }
}

/* 참여 버튼 */
.joinArea {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 40px 0;
  padding-top: 40px;
  border-top: 1px solid $C_COOL_GRAY_50;

  .btnLink {
    border: 2px solid $C_COOL_GRAY_70;
    width: 312px;
    height: 64px;
    font-size: 18px;
    color: $C_COOL_GRAY_80;
  }

  .btnJoin {
    border: none;
    color: $C_COOL_GRAY_50;
    background: $C_COOL_GRAY_40;
  }
}

.eventInfo {
  width: 312px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: $C_COOL_GRAY_60;
}
