@import '../../styles/base.scss';
@import '../../styles/common.scss';
@import '../../styles/font.scss';
@import '../../styles/mixins.scss';
@import '../../styles/reset.scss';

$COMPONENT_IMG_PATH: '@assets';

.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 120px 0;

  .mainTitle {
    margin-bottom: 24px;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: $C_JOBDA_BLACK;
  }

  .subInfo {
    margin-top: 16px;
    font-size: 14px;
    font-weight: 500;
    color: $C_COOL_GRAY_60;
  }
}

.wrapper {
  position: relative;

  .banner {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-top: 120px;
  }
}

.floatingBar {
  position: sticky;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 80px;
  left: 0;
  bottom: 0;
  background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 9;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 46px;
  border-radius: 23px;

  &.btnBlack {
    background-color: $C_JOBDA_BLACK;
    font-size: 14px;
    font-weight: 700;
    color: $C_WHITE;
  }
}
