@import '../matchIntroduce.module.scss';

.commonBox {
  padding: 96px 0 64px;

  .subTitle {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    color: $C_COOL_GRAY_70;
  }

  .btnBlack {
    margin-top: 16px;
    width: 328px;
  }
}

/* 참여하기 */
.joinInner {
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  width: 328px;
  gap: 64px;

  .joinBox {
    display: flex;
    flex-direction: column;
    align-items: center;

    .joinTitle {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 24px;
      width: 100%;
      background-color: $C_WHITE;
      border-radius: 16px;

      /* 이미지 */
      &::after {
        content: '';
        margin-top: 24px;
        width: 100%;
        height: 280px;
        border-radius: 0 0 16px 16px;
      }

      &.step1 {
        &::after {
          background-image: url(../@assets/matching5.png);
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        }
      }

      &.step2 {
        &::after {
          background-image: url(../@assets/matching6.png);
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        }
      }

      /* 폰트 */
      .step {
        @include fontStyle($B2_B, $C_LIGHT_GREEN_100);
      }

      .subText {
        margin-top: 16px;
        @include fontStyle($H4_B, $C_COOL_GRAY_60);
      }

      .mainText {
        margin-top: 2px;
        @include fontStyle($H2_B, $C_COOL_GRAY_80);
      }
    }
  }
}
