@import '../matchIntroduce.module.scss';

.commonBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 0;
  height: 100vh;
  background-image: url(../@assets/sangdong.png);
  background-size: cover;
  background-position: center;

  .mainTitle {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: $C_WHITE;
  }

  .subTitle {
    opacity: 0.6;
    color: $C_WHITE;
  }

  .btnBlack {
    margin-top: 48px;
  }
}
