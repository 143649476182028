@import '../commonStyle.module.scss';
$COMPONENT_IMG_PATH: $STATIC_IMG_PATH + '/pass/interview';

.logoList {
  margin-top: 40px;
  width: 100%;
  overflow: hidden;

  .logoFlow {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .logoImg {
    animation-duration: 110s;
    animation-iteration-count: infinite;
    animation-name: flow-ani;
    animation-timing-function: linear;
    background-position: 0 0;
    background-repeat: repeat-x;
    height: 70px;

    &:first-child {
      background-image: url(../@assets/school1.png);
      background-size: 4576px 70px;
      width: 4576px;
    }

    &:nth-child(2) {
      animation-duration: 100s;
      animation-name: rev-flow-ani;
      background-image: url(../@assets/school2.png);
      background-size: 4224px 70px;
      width: 4224px;
    }

    &:nth-child(3) {
      background-image: url(../@assets/school3.png);
      background-size: 4576px 70px;
      width: 4576px;
    }

    @keyframes flow-ani {
      0% {
        background-position: 0 0;
      }
      100% {
        background-position: 4576px 0;
      }
    }

    @keyframes rev-flow-ani {
      0% {
        background-position: 0 0;
      }
      100% {
        background-position: -6105px 0;
      }
    }
  }
}

.btnWrap {
  .btnBlack {
    width: 160px;
  }
}
