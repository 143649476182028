@import '../accaIntro.module.scss';

.previewInner {
  margin-top: 40px;

  .Tabs {
    display: flex;
    justify-content: center;
    gap: 8px;

    .Tab {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 14px 20px;
      width: 104px;
      height: 48px;
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid #cfd1d5;
      background: #fff;
      @include fontStyle($B3_M, $C_COOL_GRAY_80);

      @media screen and (max-width: $MOBILE_SCREEN) {
        font-size: 12px;
      }

      &.active {
        border: 1px solid $C_BLACK;
        background: $C_BLACK;
        @include fontStyle($B3_M, $C_WHITE);

        @media screen and (max-width: $MOBILE_SCREEN) {
          font-size: 12px;
        }
      }

      &:hover {
        background: $C_COOL_GRAY_90;
        color: $C_WHITE;
      }
    }
  }

  .tabContent {
    display: flex;
    justify-content: center;

    .video {
      margin-top: 24px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      width: 328px;
      height: 348px;
      border-radius: 16px;
      background-color: #fff;
      box-shadow: 16px 32px 32px 0px rgba(0, 0, 0, 0.06);
    }
  }
}

/* 폰트 */
.title {
  margin-top: 40px;
  font-size: 34px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.34px;
  color: $C_JOBDA_BLACK;

  .smallTitle {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }
}

.subTitle {
  margin-top: 32px;
  text-align: center;
  @include fontStyle($B1P_M, $C_COOL_GRAY_70);

  @media screen and (max-width: $MOBILE_SCREEN) {
    margin: 24px 0;
    @include fontStyle($B3P_M, $C_COOL_GRAY_70);
  }
}

.textInner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 32px;

  @media screen and (max-width: $MOBILE_SCREEN) {
    margin-top: 0px;
  }

  .text {
    border-bottom: 1px solid $C_JOBDA_BLACK;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
  }
}
