@import '../commonStyle.module.scss';

// 역량검사 분석 결과표
.slideDark {
  background-color: $C_JOBDA_BLACK;

  .basicTitle {
    margin-bottom: 80px;
  }
}

.sliderItem {
  width: 360px;
  height: 282px;

  @for $i from 1 through 3 {
    &.type#{$i} {
      background-image: url(../@assets/result#{$i}.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center bottom;
    }
  }
}

.slideInner {
  width: 360px;
  height: 282px;
}
