
// $COMPONENT_IMG_PATH: $STATIC_IMG_PATH + '/v2/components/phs/pst/result';

$TABLE_Z_INDEX: 50;
$ROW_Z_INDEX: 40;

.lineChartArea {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  user-select: none;
}

.columnArea {
  width: 50px;
  height: calc(100% - 29px + 18px); // rowArea 높이 + column 텍스트 높이
  transform: translateY(-9px); // column 텍스트 높이 / 2
  text-align: right;

  &.small {
    .column {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .columnWrap {
    max-width: 42px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .column {
    display: flex;
    align-items: center;
    justify-content: right;
    width: 100%;
    font-family: 'Pretendard', sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #6a6a6a;
    white-space: nowrap;
  }
}

.chartWrap {
  position: relative;
  width: 100%;
  height: 100%;
  padding-left: 32px;

  &.small {
    padding-left: 12px;
    padding-right: 12px;
  }

  .tableArea {
    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(100% - 29px);
    z-index: $TABLE_Z_INDEX;
  }

  .dividerArea {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #9fa4ab;

    .divider {
      width: 100%;
      height: 100%;
      border-top: 1px solid #e7e8ea;
    }
  }

  .lineArea {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    &.active{
      animation: slide 1s cubic-bezier(0.4, 0, 0.2, 1);
      animation-fill-mode: forwards;
      animation-delay: 0.2s;}
  }

  .pointsArea {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;

    .dataWrap {
      position: relative;
      width: 100%;
      height: 100%;

      .line {
        position: absolute;
        bottom: 0;
        right: -1px;
        display: block;
        border-right: 2px solid;

        &.small {
          border-right: 1px solid;
          right: 0;
        }

        &.fadeIn {
          &.active{
            animation: fadeIn 0.2s cubic-bezier(0.4, 0, 0.2, 1);
            animation-fill-mode: forwards;
            opacity: 0;
          }
          
        }
      }

      .dataBtn {
        position: absolute;
        bottom: -8px;
        right: -9px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        &.active{
          
          animation: fadeIn 0.2s cubic-bezier(0.4, 0, 0.2, 1);
          animation-fill-mode: forwards;}
        
        opacity: 0;

        &.small {
          width: 6px;
          height: 6px;
          bottom: -3px;
          right: -3px;

          .tooltipArea {
            left: 10px;
          }

          &::after {
            content: none;
          }
        }

        &::after {
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          content: '';
        }
      }

      .tooltipArea {
        position: absolute;
        display: none;
        left: 16px;
        opacity: 0;

        &::after {
          content: '';
          position: absolute;
          left: 50%;
          transform: translate(-50%, -0.5px);
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 7px solid;
        }

        &.top {
          &.active{
            animation: moveBoxTop 1.2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
          }
          
        }

        &.on {
          display: block;
          &.active{
            animation: fadeIn 0.7s cubic-bezier(0.4, 0, 0.2, 1), moveBoxTop 1.2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
            animation-fill-mode: forwards;
          }
          
        }
      }

      &.black {
        .line {
          border-color: #121619;
        }

        .dataBtn {
          background-color: #e7e8ea;

          &.small {
            background-color: #9fa4ab;
          }

          &::after {
            background-color: #44474b;
          }
        }

        .tooltipArea {
          &::after {
            border-top-color: #121619;
          }
        }

        &.on {
          .dataBtn {
            background-color: #121619;

            &.small {
              width: 12px;
              height: 12px;
              background-color: #fff;
              border: 3px solid #25282b;
              bottom: -6px;
              right: -6px;
            }

            &::after {
              background-color: #fff;
            }
          }
        }
      }

      &.green {
        .line {
          border-color: #52c564;
        }

        .dataBtn {
          background-color: #e0f9e4;

          &.small {
            background-color: #92e6a3;
          }

          &::after {
            background-color: #52c564;
          }
        }

        .tooltipArea {
          &::after {
            border-top-color: #52c564;
          }
        }

        &.on {
          .dataBtn {
            background-color: #52c564;

            &.small {
              width: 12px;
              height: 12px;
              background-color: #fff;
              border: 3px solid #52c564;
              bottom: -6px;
              right: -6px;
            }

            &::after {
              background-color: #fff;
            }
          }
        }
      }

      &.blue {
        .line {
          border-color: #2b53ae;
        }

        .dataBtn {
          background-color: #c3d1ef;

          &.small {
            background-color: #8ba3df;
          }

          &::after {
            background-color: #2b53ae;
          }
        }

        .tooltipArea {
          &::after {
            border-top-color: #2b53ae;
          }
        }

        &.on {
          .dataBtn {
            background-color: #2b53ae;

            &.small {
              width: 12px;
              height: 12px;
              background-color: #fff;
              border: 3px solid #2b53ae;
              bottom: -6px;
              right: -6px;
            }

            &::after {
              background-color: #fff;
            }
          }
        }
      }

      &.empty {
        border: none;
      }
    }
  }

  .rowArea {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18px;
    margin-top: 12px;
    z-index: $ROW_Z_INDEX;
  }

  .rowWrap {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .row {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
    transform: translateX(50%);

    .rowText {
      position: absolute;
      font-family: 'Pretendard', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.01em;
      color: #6a6a6a;
    }

    &.small {
      .rowText {
        font-size: 12px;
        line-height: 16px;
      }
    }

    &.on {
      font-weight: 700;
      color: #121619;

      &.small {
        font-weight: 500;
      }
    }
  }

  .btn {
    position: absolute;
    bottom: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background-color: #fff;
    border: 1px solid #e7e8ea;
    border-radius: 50%;
    z-index: 100;

    &::after {
      display: block;
      width: 16px;
      height: 16px;
      background-size: cover;
      background-repeat: no-repeat;
      content: '';
    }

    &.left {
      left: 14px;

      &::after {
        // background-image: url($COMPONENT_IMG_PATH + '/iconLeft.png');
      }
    }

    &.right {
      right: 14px;

      &::after {
        // background-image: url($COMPONENT_IMG_PATH + '/iconRight.png');
      }
    }
  }
}

@keyframes moveBoxTop {
  0% {
    transform: translate(calc(-50% - 8px), calc(-50% - 8px - 15px - 2px));
  }

  50% {
    transform: translate(calc(-50% - 8px), calc(-50% - 8px - 15px + 4px));
  }

  100% {
    transform: translate(calc(-50% - 8px), calc(-50% - 8px - 15px - 2px));
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slide {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}
