@import '../commonStyle.module.scss';

.bannerInner {
  background: linear-gradient(180deg, #63d77e 0%, #90e27e 100%);

  .text {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.18px;
  }

  .textImport {
    margin-top: 12px;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.28px;
  }

  .bannerText {
    margin-top: 40px;
    width: 100%;
    height: 215px;
    background-image: url(../@assets/bannerTxt.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .btnBasic {
    margin-top: 26px;
  }
}
