@import '../../styles/base.scss';
@import '../../styles/common.scss';
@import '../../styles/font.scss';
@import '../../styles/mixins.scss';
@import '../../styles/reset.scss';

.wrap {
  background: $V2C_GRAY_25;
}
// 공통 박스
.commonBox {
  opacity: 0;
  transform: translateY(24px);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;

  &.on {
    transform: translateY(0);
    opacity: 1;
  }
}
// 공통 버튼
.btnApply {
  @include Flex($align: center, $justify: center);
  flex: none;
  height: 56px;
  margin-top: $space_XXL;
  padding: 0 $space_L 0 $space_XL;
  border-radius: 99px;
  background-color: $V2C_GRAY_800;
  color: #fff;

  &::after {
    content: '';
    width: 32px;
    height: 32px;
    background-image: url('./img/arrowRight.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition: transform 0.3s ease;
  }
}
