@import '../commonStyle.module.scss';
$COMPONENT_IMG_PATH: $STATIC_IMG_PATH + '/pass/interview';

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 298px;
  background: linear-gradient(113deg, #92e6a3 20.59%, #52c564 79.41%), #fff;
  padding-bottom: 74px;

  .titleWrap {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: $C_WHITE;
  }

  .btnWrap {
    .btn {
      margin: 24px auto 0;
    }
  }
}
