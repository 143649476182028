@import '../../commonStyle.module.scss';

.wrap {
  position: relative;
  width: 290px;
  height: 100%;

  /* 인재 모집중 썸네일 카드 */
  .thumbnail {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 354px;
    border: 2px solid transparent;

    .cardImg {
      display: flex;
      width: 286px;
      height: 350px;
      border-radius: 6px;
    }

    .detailBtn {
      position: absolute;
      top: 0px;
      left: 0;
      width: 286px;
      height: 200px;
      display: flex;
      align-items: center;
      padding: 144px 0 0 24px;
      color: #333436;
      font-size: 12px;
      font-weight: 500;
      text-decoration: underline;
      z-index: 3;
    }

    .interviewBtn {
      position: absolute;
      bottom: 16px;
      left: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 254px;
      height: 40px;
      border-radius: 8px;
      border: 1px solid #20c997;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      background: #20c997;
      color: #fff;

      &::before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 4px;
        background: url(../../@assets/unChecked.svg) no-repeat;
      }

      &.isMyReservation {
        z-index: 2;
      }
    }

    &.checked {
      border-radius: 8px;
      border: 2px solid #20c997;

      .interviewBtn {
        background: #e6fcf5;
        color: #12b886;

        &::before {
          background: url(../../@assets/delete.svg) no-repeat;
        }
      }
    }
  }

  /* 라이브 on off 상태 */
  .statusInner {
    position: absolute;

    &.on {
      width: 100%;
      height: 32px;
      top: 12px;
      right: 15px;
      width: 54px;
      height: 16px;
      background: url(../../@assets/stateOn.svg) no-repeat;
      z-index: 1;
    }

    &.off {
      top: -2px;
      left: -2px;
      width: calc(100% + 4px);
      height: 496px;
      border: #dfe0e2;
      background-color: rgba(255, 255, 255, 0.6);
      z-index: 1;

      &::before {
        content: '';
        position: absolute;
        top: 14px;
        right: 12px;
        width: 110px;
        height: 32px;
        background: url(../../@assets/stateOff.svg) center no-repeat;
        opacity: 0.8;
      }
    }
  }

  .companyInfo {
    position: relative;
    display: flex;
    width: 286px;
    align-items: center;
    flex-direction: column;
    gap: 4px;
    margin-top: 12px;

    .stateOrder {
      display: flex;
      align-items: center;
      gap: 16px;
      width: 282px;
      height: 40px;
      padding: 0 16px;
      border-radius: 20px;
      border: 1px solid #dfe0e2;
      background: #fff;
      word-break: keep-all;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: #000;

      &::before {
        content: '';
        display: inline-block;
        flex: none;
        width: 20px;
        height: 20px;
        background: url(../../@assets/orderOn.svg) no-repeat;
      }

      &.done {
        border: none;
        border: 1px solid #18191b;
        background-color: #f7f7f7;

        &::before {
          background: url(../../@assets/orderOff.svg) no-repeat;
        }
      }
    }
  }
}

/* 토스트 메세지 */
.toast {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

/* 결과표 스타일 */
.result {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding-top: 32px;
  width: 1214px;

  .portfolio {
    position: absolute;
    bottom: 414px;
    width: 100%;
    height: 80px;
    background-color: transparent;
  }

  .resultImg {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .moreInfo {
    position: absolute;
    bottom: 125px;
    right: 0;
    width: 300px;
    height: 80px;
    background-color: transparent;
    opacity: 0.5;
  }
}

/* 플로팅 버튼 */
.floatingButton {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 96px;
  bottom: 0;
  left: 0;
  opacity: 0.9;
  background: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(20px);
  z-index: 100;

  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .text {
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 12px;
      font-weight: 500;

      .lottieWrap {
        width: 36px;
        height: 36px;
      }
    }

    .action {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      padding: 0 16px;
      width: fit-content;
      height: 32px;
      background: linear-gradient(90deg, #5cff7d 0%, #00fff5 89.06%);
      font-size: 14px;
      font-weight: 700;
    }
  }
}
