@import '../commonStyle.module.scss';

.wrap {
  padding-bottom: 56px;
}

// 추가 혜택 이미지 영역
.benefitWrap {
  @for $i from 1 through 3 {
    &.type#{$i} {
      &:after {
        background-image: url(../@assets/m_sec7_img#{$i}.png);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }

  // 혜택 1 분기 처리
  &.type1 {
    position: relative;
    flex-direction: column;
    height: 100%;

    &:after {
      width: 100%;
      height: 194px;
    }
  }
}
