@import '../commonStyle.module.scss';

// 설명문 영역
.descArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 56px;

  &:after {
    flex: none;
    width: 328px;
    background-size: contain;
    background-repeat: no-repeat;
    content: '';
  }

  // 설명문 이미지
  @for $i from 1 through 3 {
    &.type#{$i} {
      &:after {
        background-image: url(../@assets/imgDesc#{$i}.png);
      }
    }
  }

  // 순서에 따라 높이값 수정
  &.type1 {
    &:after {
      height: 142px;
    }
  }

  &.type2 {
    &:after {
      height: 265.5px;
    }
  }

  &.type3 {
    &:after {
      height: 596px;
    }
  }
}

.title {
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  color: $C_COOL_GRAY_60;
}
