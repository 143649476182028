@charset 'UTF-8';

@import './variables/variables.scss';

*,
:before,
:after {
  box-sizing: border-box;
}

html,
body,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
table,
th,
td,
button,
form,
input,
select,
textarea,
fieldset,
legend,
iframe,
figure {
  margin: 0;
  padding: 0;
  font-size: 100%;
}

html,
body {
  height: 100%;
  -webkit-text-size-adjust: none;
}

body {

  font-family: $F_PRETENDARD;
  font-size: 12px;
  line-height: 1.4;
  letter-spacing: -0.01em;
  color: #121619;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-underline-position: under;
}

address,
em {
  font-style: normal;
}

fieldset,
img,
svg {
  border: 0;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

select {
  vertical-align: middle;
}

input {
  border-radius: 0;
  font-family: $F_PRETENDARD;
  vertical-align: middle;

  &::-webkit-search-cancel-button {
    appearance: none;
  }

  &::-ms-clear {
    display: none;
  }

  // ms 계열 브라우저 - 비밀번호 보기 버튼 제거
  &::-ms-reveal {
    display: none;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    display: none;
  }

  &::placeholder {
    font-family: $F_PRETENDARD;
  }
}

i {
  font-style: normal;
}

dl,
ol,
ul {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;

  &[role='button'] {
    cursor: pointer;
  }
}

button {
  appearance: none;
  border: 0;
  background: transparent;
  font: inherit;
  outline: none;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
}

textarea {
  overflow: auto;
  font-family: $F_PRETENDARD;
  resize: none;

  &::placeholder {
    font-family: $F_PRETENDARD;
  }
}
