@import '../../styles/base.scss';
@import '../../styles/common.scss';
@import '../../styles/font.scss';
@import '../../styles/mixins.scss';
@import '../../styles/reset.scss';

$FADEIN_DURATION: 0.5s;

.wrap {
  font-family: $F_PRETENDARD;
  background: $C_JOBDA_WHITE;
}

/* 공통 박스 */
.commonBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 121px;
  transform: translateY(24px);
  transition: transform $FADEIN_DURATION ease-in-out,
    opacity $FADEIN_DURATION ease-in-out;
  opacity: 0;

  &.on {
    transform: translateY(0);
    opacity: 1;
  }

  /* 공통 폰트 */
  .mainTitle {
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    line-height: 38px;
    color: $C_JOBDA_BLACK;

    .light {
      line-height: 32px;
      color: $C_COOL_GRAY_60;
    }
  }

  .subTitle {
    margin-top: 32px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    color: $C_COOL_GRAY_70;
  }

  /* 공통 버튼 */
  .btnBlack {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 22px;
    width: fit-content;
    height: 46px;
    border-radius: 23px;
    background-color: $C_JOBDA_BLACK;
    @include fontStyle($B3_B, $C_WHITE);

    &.btnIcon {
      padding: 0 12px 0 22px;

      &::after {
        content: '';
        margin-left: 4px;
        width: 32px;
        height: 32px;
        background-image: url(./@assets/icon_arrow_forward_light.png);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}
