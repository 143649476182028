$space_NONE: 0px;
$space_XXXXS: 2px;
$space_XXXS: 4px;
$space_XXS: 6px;
$space_XS: 8px;
$space_S: 10px;
$space_M: 12px;
$space_L: 16px;
$space_XL: 24px;
$space_XXL: 32px;
$space_XXXL: 40px;
$space_XXXXL: 48px;
