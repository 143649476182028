@import '../commonStyle.module.scss';

.wrap {
  position: relative;
  width: 100vw;
  height: calc(100vh - 56px);
  padding: 0;
  background-color: $C_JOBDA_BLACK;

  .introBg {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100%;
    background-image: url(../@assets/keyVisual.png);
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    z-index: 1;
  }
}
/* 
.mainKeyVisual {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.introInner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  z-index: 2;
}

.eventGift {
  position: relative;
  width: 100vw;
  height: 256px;
  background-image: url(../@assets/mainGift.png);
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  z-index: 2;
} */

//텍스트 영역
/* 
.subTitle {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  color: $C_JOBDA_BLACK;
}

.mainTitle {
  margin-top: 16px;

  .mainText {
    font-size: 34px;
    font-weight: 500;
    line-height: 44px;
    color: $C_JOBDA_BLACK;
  }

  .mainTextImport {
    font-weight: 700;
    line-height: 140%;

    .importDot {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: -4px;
        left: 50%;
        transform: translateX(-50%);
        width: 4px;
        height: 4px;
        border-radius: 4px;
        background-color: $C_GREEN_150;
      }
    }
  }
}

.eventDate {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;

  .eventOpen {
    padding: 0 13px;
    border-left: 1px solid #1216197e;
    border-right: 1px solid #1216197e;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    color: $C_JOBDA_BLACK;
  }

  .eventTime {
    margin-left: 24px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: $C_JOBDA_BLACK;
  }
}
 */
