@import '../../matchIntroduce.module.scss';

.rollingIntro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 24px;
  font-size: 34px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.34px;
  color: $C_WHITE;
  background-color: $C_JOBDA_BLACK;

  .rollingText {
    display: flex;
    justify-content: flex-start;
    font-size: 32px;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: -0.32px;
    align-items: center;
    height: 64px;
    .visualWrap {
      margin-left: 18px;
      width: 60px;
      height: 60px;
      flex: none;
    }

    .spec {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      color: $C_COOL_GRAY_80;
    }
  }
}

.rollingArea {
  width: 270px;
  line-height: 64px;
  border-radius: 8px;
  border: 5px solid $C_COOL_GRAY_90;
  user-select: none; //롤링 영역 사용자 드레그 막음

  .change-word {
    height: 64px;
    overflow: hidden;
    text-align: center;
    width: fit-content;
  }

  .change-inner {
    animation: rotate 20s ease infinite 5s;

    @keyframes rotate {
      from {
        transform: translateY(0);
      }

      11.1% {
        transform: translateY(-64px);
      }

      22.2% {
        transform: translateY(-128px);
      }

      33.3% {
        transform: translateY(-192px);
      }

      44.4% {
        transform: translateY(-256px);
      }

      55.5% {
        transform: translateY(-320px);
      }

      66.6% {
        transform: translateY(-384px);
      }

      77.7% {
        transform: translateY(-448px);
      }

      to {
        transform: translateY(-512px);
      }
    }
  }
}
