body,
html {
  font-family: 'Pretendard', sans-serif;
}

@font-face {
	font-family: 'Pretendard';
	font-style: normal;
	font-display: swap;
	font-weight: 400;
	src: local('Pretendard Regular'),
    url('./Pretendard-Regular.subset.woff2') format('woff2'), url('./Pretendard-Regular.subset.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-style: normal;
	font-display: swap;
	font-weight: 500;
	src: local('Pretendard Medium'),
    url('./Pretendard-Medium.subset.woff2') format('woff2'), url('./Pretendard-Medium.subset.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-style: normal;
	font-display: swap;
	font-weight: 600;
	src: local('Pretendard SemiBold'),
    url('./Pretendard-SemiBold.subset.woff2') format('woff2'), url('./Pretendard-SemiBold.subset.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-style: normal;
	font-display: swap;
	font-weight: 700;
	src: local('Pretendard Bold'),
    url('./Pretendard-Bold.subset.woff2') format('woff2'), url('./Pretendard-Bold.subset.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-style: normal;
	font-display: swap;
	font-weight: 800;
	src: local('Pretendard ExtraBold'),
    url('./Pretendard-ExtraBold.subset.woff2') format('woff2'), url('./Pretendard-ExtraBold.subset.woff') format('woff');
}