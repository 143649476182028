@import '../commonStyle.module.scss';

.btnPhsTest {
  position: fixed;
  bottom: 40px;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 228px;
  height: 46px;
  border-radius: 24px;
  background-color: #595ff4;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.14px;
  color: $C_WHITE;
  transform: translateX(-50%);
  z-index: $Z_INDEX_FIXED;
}
