@import '../detail.module.scss';

.detailInfoInner {
  // 일정 이미지
  &:after {
    width: 328px;
    height: 258px;
    margin: 0 auto;
    background-image: url(../../@assets/imgSchedule.png);
  }
}
