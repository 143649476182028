@import '../detail.module.scss';

.participateArea {
  display: flex;
  flex-direction: column;
  gap: 56px;
}

.imgText {
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  color: $C_COOL_GRAY_60;
}

.bannerInner {
  position: relative;
  display: flex;
  margin-top: 100px;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 16px 0px;
  background: #00ff9d;
  font-family: $F_RIX;

  .bannerTitle {
    color: #4021ff;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.24px;
  }

  &::before {
    content: '';
    position: absolute;
    top: -147px;
    width: 328px;
    height: 148px;
    background-image: url(../../@assets/imgBannerTop.png);
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.detailInfoInner {
  // 참가 방법 이미지
  &:after {
    width: 328px;
    height: 190px;
    margin: 0 auto;
    background-image: url(../../@assets/imgParticipate.png);
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.detailInfoWrap {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.step {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.2px;
  color: #785cc7;
}

.desc {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.2px;
  color: #4d3eff;
}
