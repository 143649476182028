@import '../matchIntroduce.module.scss';

.commonBox {
  background-color: $C_JOBDA_WHITE;
}

.interviewBox {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 128px;

  .interviewInner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 328px;
    height: 266px;
    padding: 40px;
    border-radius: 16px;
    background: $C_WHITE;
    box-shadow: 32px 64px 64px 0px rgba(0, 0, 0, 0.06);

    /* 인사담당자 인터뷰 1~3 */
    &:first-child {
      .contents {
        &::before {
          background-image: url(../@assets/logo1.png);
          background-size: contain;
          background-position: left;
          background-repeat: no-repeat;
        }
      }

      &::after {
        background-image: url(../@assets/hr1.png);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    &:nth-child(2) {
      .contents {
        &::before {
          background-image: url(../@assets/logo2.png);
          background-size: contain;
          background-position: left;

          background-repeat: no-repeat;
        }
      }

      &::after {
        background-image: url(../@assets/hr2.png);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    &:nth-child(3) {
      .contents {
        &::before {
          background-image: url(../@assets/logo3.png);
          background-size: contain;
          background-position: left;

          background-repeat: no-repeat;
        }
      }

      &::after {
        background-image: url(../@assets/hr3.png);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    .contents {
      font-size: 16px;
      font-weight: 700;
      line-height: 26px;
      color: $C_JOBDA_BLACK;

      &::before {
        content: '';
        margin-bottom: 24px;
        display: block;
        width: 140px;
        height: 40px;
      }
    }

    .who {
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      color: $C_COOL_GRAY_70;
    }

    &::after {
      content: '';
      position: absolute;
      right: 40px;
      bottom: 40px;
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }
  }
}
