@import '../../accaIntro.module.scss';

.companyItem {
  display: flex;
  flex-direction: column;
  width: 373px;
  padding: 64px 48px;
  border-radius: 24px;
  background: #fff;
  box-shadow: 32px 64px 64px 0px rgba(0, 0, 0, 0.06);

  @media screen and (max-width: $MOBILE_SCREEN) {
    width: 304px;
    padding: 40px;
    border-radius: 16px;
  }

  .logoImg {
    height: 56px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (max-width: $MOBILE_SCREEN) {
      height: 44px;
    }
  }

  &:first-child {
    .logoImg {
      width: 96px;
    }
  }

  &:nth-child(2) {
    .logoImg {
      width: 44px;
    }
  }

  &:nth-child(3) {
    .logoImg {
      width: 131px;
    }
  }

  .review {
    margin-top: 48px;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.2px;
    white-space: pre-wrap;
    color: $C_JOBDA_BLACK;

    @media screen and (max-width: $MOBILE_SCREEN) {
      margin-top: 24px;
      @include fontStyle($B2P_B, $C_JOBDA_BLACK);
    }
  }

  .reviewer {
    margin-top: 32px;
    @include fontStyle($B2P_M, $C_COOL_GRAY_70);

    @media screen and (max-width: $MOBILE_SCREEN) {
      margin-top: 16px;
      @include fontStyle($B4P_M, $C_JOBDA_BLACK);
    }
  }
}
