@import '../commonStyle.module.scss';

// 미션 1 - 미션 이미지 영역
.sliderItem {
  @for $i from 1 through 3 {
    &.type#{$i} {
      background-image: url(../@assets/m_sec2_img#{$i}.png);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}

// 미션 1 - 혜택 이미지 영역
.benefitWrap {
  @for $i from 1 through 2 {
    &.type#{$i} {
      &:after {
        background-image: url(../@assets/m_sec3_img#{$i}.png);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}
