@import '../index.module.scss';

.wrapper {
  position: relative;
  @include Flex($dir: column, $align: center, $justify: flex-start);
  text-align: center;
  padding: 80px 0;
  overflow: hidden;
  background: $V2C_GRAY_25;
}

.badge {
  @include Flex($align: center, $justify: center, $gap: $space_XXXS);
  height: 36px;
  padding: 0 $space_L;
  margin-bottom: $space_L;
  background: $V2C_GRAY_100;
  border-radius: $radius_CIRCULAR;
  border: 1px solid #DFE0E2;
}

.logoFlow {
  @include Flex($dir: column, $gap: $space_M);
  margin-top: $space_XXXXL;
  overflow: hidden;

  > :first-child {
    animation: flowList 20s infinite alternate-reverse;
    animation-timing-function: ease-in-out;
  }

  > :last-child {
    animation: flowListReverse 20s infinite alternate-reverse;
    animation-timing-function: ease-in-out;
  }
}

.logoItem {
  @include Flex($gap: $space_M);
  white-space: nowrap;
}

.logoWrapper {
  position: relative;
  width: 140px;
  height: 140px;
}

.logoImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}


@keyframes flowList {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-25%);
  }
}

@keyframes flowListReverse {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(25%);
  }
}
