@import '../index.module.scss';

.commonBox {
  position: relative;
  @include Flex($dir: column, $align: center, $justify: center);
  width: 100%;
  padding: 80px 0;
  background: $V2C_GRAY_25;
  overflow: hidden;

  .titleArea {
    @include Flex($dir: column, $align: center, $justify: center);
    margin-bottom: 80px;
    text-align: center;
  }
}
