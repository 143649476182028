@import '../commonStyle.module.scss';

.contentInner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-top: 40px;

  .basicText {
    text-align: center;

    &::after {
      content: '';
      display: block;
      margin: 24px auto 0;
      height: 496px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      filter: drop-shadow(24px 32px 40px rgba(0, 0, 0, 0.06));
    }

    &:first-child {
      position: relative;

      &::after {
        width: 282px;
        background-image: url(../@assets/event1.png);
      }

      &::before {
        position: absolute;
        content: '';
        display: block;
        top: 182px;
        left: -16px;
        width: 312px;
        height: 92px;
        border-radius: 10px;
        z-index: 1;
        box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.25);
        background-image: url(../@assets/event1_sub.png);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    &:nth-child(3) {
      &::after {
        width: 370px;
        background-image: url(../@assets/event2.png);
      }
    }

    &:nth-child(5) {
      &::after {
        width: 370px;
        background-image: url(../@assets/event3.png);
      }
    }
  }
}
