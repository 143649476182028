
.headerArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  padding: 0 20px;
  background-color: #25282b;
  border: 1px solid #121619;
  border-radius: 8px 8px 0 0;

  &.month {
    justify-content: center;
  }

  .dateWrap {
    display: flex;
  }

  .date {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    cursor: pointer;

    ~ .date {
      margin-left: 8px;
    }

    .icon {
      display: block;
      width: 16px;
      height: 16px;
      margin-left: 2px;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url('../../../../../assets/_v2/_common/icon_down_small.png');
    }
  }

  .buttonWrap {
    display: flex;

    &.month {
      display: none;
    }
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    padding: 0;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;

   

    &.left {
      background-image: url('../../../../../assets/_v2/_common/icon_left_small.png');
    }

    &.right {
      background-image: url('../../../../../assets/_v2/_common/icon_right_small.png');
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  
  .yearButtonWrap {
    position: absolute;
    right: 24px;
    bottom: 16px;
    display: flex;
    align-items: center;
    padding: 4px;
    border: 1px solid #cfd1d5;
    border-radius: 4px;

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      padding: 0;
      background-size: cover;
      background-repeat: no-repeat;
      cursor: pointer;

      &.left {
        background-image: url('../../../../../assets/_v2/_common/icon_left_arrow_small.svg');
      }

      &.right {
        background-image: url('../../../../../assets/_v2/_common/icon_right_arrow_small.svg');
      }
    }

    .line {
      width: 1px;
      height: 14px;
      background-color:#e7e8ea;
      margin: 0 4px;
    }
  }
}
