@import '../commonStyle.module.scss';

.commonBox {
  padding: 80px 0;
  background-color: $C_JOBDA_BLACK;

  .desc {
    text-align: center;
    background: linear-gradient(90deg, #4cd668 0%, #4cced6 100%);
    background-clip: text;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: transparent;

    &.mainDesc {
      display: flex;
      flex-direction: column;
      margin-bottom: 8px;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;

      &::before {
        content: '';
        display: inline-block;
        width: 206px;
        height: 50px;
        margin-bottom: 40px;
        background: url(../@assets/endingTalk.png) no-repeat;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }

  .btnMove {
    display: flex;
    align-items: center;
    margin-top: 56px;
    padding: 10px 28px 10px 40px;
    height: 54px;
    border-radius: 27px;
    background-color: $C_WHITE;
    @include fontStyle($B3_B, $C_JOBDA_BLACK);

    &:hover {
      background-color: $C_COOL_GRAY_30;
    }

    svg {
      margin-left: 4px;
      transform: rotate(90deg);
    }
  }
}
