@import '../commonStyle.module.scss';

.wrap {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;

  .cardList {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }

  .floatingButton {
    position: fixed;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 16px 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(20px);
    z-index: 100;

    .inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;

      .text {
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 16px;
        font-weight: 500;

        .count {
          color: #5cff7d;
          font-weight: 700;
        }

        .lottieWrap {
          width: 56px;
          height: 56px;
        }
      }

      .subMessage {
        margin-left: 4px;
        font-size: 14px;
        font-weight: 500;
      }

      .action {
        color: #38d9a9;
        font-size: 12px;
        font-weight: 500;
        text-decoration-line: underline;

        &:hover {
          color: #12b886;
        }

        .btnComplete {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          height: 40px;
          padding: 10px 16px;
          font-size: 14px;
          font-weight: 600;
          border-radius: 6px;
          color: #000;
          background: linear-gradient(90deg, #5cff7d 0%, #00fff5 89.06%);

          &::before {
            content: '';
            width: 20px;
            height: 20px;
            margin-right: 4px;
            background: url(../@assets/icon_done.svg) no-repeat;
          }

          &.btnCancel {
            background: #495057;
            color: #adb5bd;

            &::before {
              background: url(../@assets/icon_cancel.svg) no-repeat;
            }
          }
        }
      }
    }
  }
}

/* 예약 완료 모달 */
.modalComplete {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 327px;
  padding: 24px 0;
  background-color: #fff;
  box-shadow: 0px 32px 64px -12px rgba(16, 24, 40, 0.14);
  border-radius: 12px;

  .lottie {
    width: 120px;
    height: 120px;
  }
  .title {
    font-size: 20px;
    font-weight: 600;
  }

  .subTitle {
    text-align: center;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #838486;
  }

  .btnClose {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    width: 57px;
    height: 40px;
    border-radius: 6px;
    background: #333436;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
  }
}
