@import '../commonStyle.module.scss';

.rabbits {
  margin-top: 40px;
  width: 328px;
  height: 206px;
  background-image: url(../@assets/rabbits.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
