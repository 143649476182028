@import '../commonStyle.module.scss';

.wrap {
  padding-bottom: 124px;
}

.inner {
  padding-bottom: 56px;
}
// 미션 2 - 미션 이미지 영역
.sliderItem {
  @for $i from 1 through 3 {
    &.type#{$i} {
      background-image: url(../@assets/m_sec4_img#{$i}.png);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}

// 미션 2 - 혜택 이미지 영역
.benefitWrap {
  @for $i from 1 through 3 {
    &.type#{$i} {
      &:after {
        background-image: url(../@assets/m_sec5_img#{$i}.png);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }

  // 혜택 1 분기 처리
  &.type1 {
    position: relative;
    flex-direction: column;
    height: 100%;

    &:after {
      width: 100%;
      height: 194px;
    }
  }
}

// 합격자 이력서 다운로드 버튼
.btnDownload {
  position: absolute;
  bottom: 24px;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 280px;
  height: 38px;
  border-radius: 4px;
  background-color: $C_JOBDA_BLACK;
  @include fontStyle($B3_B, $C_JOBDA_WHITE);
  transform: translateX(-50%);
  z-index: 1;

  &.disabled {
    background-color: $C_COOL_GRAY_40;
    color: $C_COOL_GRAY_60;
    opacity: 0.64;
  }
}
