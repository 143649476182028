@import './variables/colors.scss';
@import './variables/fontType.scss';

// ellipsis
// @param {number} $LINE - 줄 수
// @param {px} $LINE_HEIGHT - 한줄 높이
@mixin ellipsis($LINE: 1, $LINE_HEIGHT: 1.5em) {
  @if ($LINE == 1) {
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
  } @else {
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: $LINE;
    -webkit-box-orient: vertical;
    max-height: $LINE_HEIGHT * $LINE; // webkit 이외의 브라우저 대응
    line-height: $LINE_HEIGHT;
    text-overflow: ellipsis;
    word-break: break-word;
  }
}

// 공통 버튼 스타일
// @param {string} $TYPE - 버튼 타입
// @param {string} $SIZE - 버튼 크기
@mixin commonBtnStyle($TYPE: 'primary', $SIZE: 'large') {
  position: relative;
  text-align: center;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }

  // 스타일에 따른 처리
  @if ($TYPE == 'primary') {
    width: 228px;
    border-radius: 4px;
    background-color: $C_GREEN_100;
    font-family: $F_PRETENDARD;
    font-weight: 700;
    color: $C_JOBDA_WHITE;

    // 크기에 따른 처리
    @if ($SIZE == 'large') {
      height: 64px;
      font-size: 17px;
      line-height: 64px;
      letter-spacing: -0.41px;
    } @else if($SIZE == 'medium') {
      height: 56px;
      font-size: 17px;
      line-height: 56px;
      letter-spacing: -0.41px;
    } @else if($SIZE == 'small') {
      height: 44px;
      font-size: 15px;
      line-height: 44px;
      letter-spacing: -0.36px;
    }

    &:hover {
      background-color: $C_GREEN_200;
    }

    &:disabled {
      opacity: 0.5;

      &:hover {
        background-color: $C_GREEN_100;
      }
    }
  }

  // 스타일에 따른 처리
  @else if($TYPE == 'secondary') {
    width: 228px;
    border: 2px solid $C_GREEN_100;
    border-radius: 4px;
    background-color: $C_WHITE;
    font-family: $F_PRETENDARD;
    font-weight: 700;
    color: $C_GREEN_100;

    // 크기에 따른 처리
    @if ($SIZE == 'large') {
      height: 64px;
      font-size: 17px;
      line-height: 60px;
      letter-spacing: -0.41px;
    } @else if($SIZE == 'medium') {
      height: 56px;
      font-size: 17px;
      line-height: 52px;
      letter-spacing: -0.41px;
    } @else if($SIZE == 'small') {
      height: 44px;
      font-size: 15px;
      line-height: 40px;
      letter-spacing: -0.36px;
    }

    &:hover {
      border-color: $C_GREEN_200;
      background-color: $C_JOBDA_WHITE;
      color: $C_GREEN_200;
    }

    &:disabled {
      opacity: 0.5;

      &:hover {
        border-color: $C_GREEN_100;
        background-color: $C_WHITE;
        color: $C_GREEN_100;
      }
    }
  }

  // 스타일에 따른 처리
  @else if($TYPE == 'underline') {
    font-family: $F_PRETENDARD;
    color: $C_COOL_GRAY_80;

    // 크기에 따른 처리
    @if ($SIZE == 'large') {
      font-size: 17px;
      line-height: 24px;
      letter-spacing: -0.41px;
    } @else if($SIZE == 'medium') {
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.36px;
    } @else if($SIZE == 'small') {
      font-size: 13px;
      line-height: 18px;
      letter-spacing: -0.0866px;
    }

    &:after {
      position: absolute;
      right: 0;
      bottom: -1px;
      left: 0;
      height: 1px;
      background-color: $C_COOL_GRAY_80;
      content: '';
    }

    &:hover {
      color: $C_COOL_GRAY_70;

      &:after {
        background-color: $C_COOL_GRAY_70;
      }
    }

    &:disabled {
      color: $C_COOL_GRAY_60;

      &:after {
        background-color: $C_COOL_GRAY_60;
      }

      &:hover {
        color: $C_COOL_GRAY_60;

        &:after {
          background-color: $C_COOL_GRAY_60;
        }
      }
    }
  }
}

// 공통 인풋 스타일
@mixin commonInputStyle {
  display: inline-block;
  width: 100%;
  height: 56px;
  padding: 0 16px;
  border: 1px solid $C_COOL_GRAY_30;
  border-radius: 4px;
  background-color: $C_COOL_GRAY_30;
  font-family: $F_PRETENDARD;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.36px;
  color: $C_COOL_GRAY_90;
  outline: 0;
  vertical-align: top;

  // 생년월일 경우
  &.type2 {
    width: 212px;
  }

  // 주민등록번호 뒷자리 경우
  &.type3 {
    width: 60px;
    text-align: center;
  }

  //휴대폰 번호일 경우
  &.mobile {
    letter-spacing: 2.6px;
  }

  // 버튼(인증요청, 중복확인, 비밀번호확인)이 있는 경우
  &.withButton {
    padding-right: 100px;
  }

  // 검색이 가능한 경우(search icon)
  &.search {
    padding-left: 48px;
  }

  &.error {
    border-color: $C_ERROR;
  }

  &:focus,
  &.success {
    border-color: $C_GREEN_100;
    background-color: $C_WHITE;
  }

  &:disabled {
    background-color: $C_JOBDA_WHITE;
  }

  &::placeholder {
    font-family: $F_PRETENDARD;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.36px;
    color: $C_COOL_GRAY_60;
  }

  &:-ms-input-placeholder {
    //ie11
    font-family: $F_PRETENDARD;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.36px;
    color: $C_COOL_GRAY_60;
  }

  &::-ms-input-placeholder {
    //edge
    font-family: $F_PRETENDARD;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.36px;
    color: $C_COOL_GRAY_60;
  }
}

// 공통 box-shadow 스타일
// @param {string} $TYPE - box-shadow 타입
@mixin commonBoxShadowStyle($TYPE: 'base') {
  @if ($TYPE == 'base') {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  } @else if($TYPE == 'medium') {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
  } @else if($TYPE == 'large') {
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
  } @else if($TYPE == 'xlarge') {
    box-shadow: 0px 20px 25px rgba(0, 0, 0, 0.1), 0px 10px 10px rgba(0, 0, 0, 0.04);
  } @else if($TYPE == 'xxlarge') {
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.25);
  } @else if($TYPE == 'inner') {
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.06);
  } @else if($TYPE == 'Shadow100') {
    box-shadow: 0 1px 6px rgba($C_BLACK, 0.12);
  } @else if($TYPE == 'Shadow200') {
    box-shadow: 0 8px 16px rgba($C_BLACK, 0.12);
  } @else if($TYPE == 'Shadow300') {
    box-shadow: 0 8px 10px rgba($C_BLACK, 0.04), 0 16px 25px rgba($C_BLACK, 0.1);
  } @else if($TYPE == 'Shadow400') {
    box-shadow: 0 20px 50px rgba($C_BLACK, 0.25);
  }
}

// scroll
@mixin scroll($SCROLL_WIDTH: 4px, $IE_TRACK_COLOR: $C_WHITE) {
  $BASIC_SCROLL_WIDTH: 17px;
  $SCROLL_BAR_COLOR: $C_COOL_GRAY_40;
  $TRACK: transparent;

  overflow-x: hidden;
  overflow-y: auto;
  margin-right: calc(#{$SCROLL_WIDTH} - #{$BASIC_SCROLL_WIDTH});
  padding-right: 0;

  /*  IE scrollbar style  */
  scrollbar-face-color: $SCROLL_BAR_COLOR;
  scrollbar-shadow-color: $SCROLL_BAR_COLOR;
  scrollbar-track-color: $IE_TRACK_COLOR;
  scrollbar-arrow-color: $IE_TRACK_COLOR;

  &::-webkit-scrollbar {
    width: $BASIC_SCROLL_WIDTH;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $SCROLL_BAR_COLOR;
  }

  &::-webkit-scrollbar-track {
    background-color: $TRACK;
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
}

// 공통 font 스타일
// @param {string} $TYPE - font 타입 / $COLOR - font color
@mixin fontStyle($TYPE: $H1_B, $COLOR: $C_JOBDA_BLACK) {
  color: $COLOR;

  @if ($TYPE == $H1_B) {
    font-size: 48px;
    font-weight: 700;
    line-height: 62px;
  } @else if($TYPE == $H2_B) {
    font-size: 40px;
    font-weight: 700;
    line-height: 52px;
  } @else if($TYPE == $H3_B) {
    font-size: 32px;
    font-weight: 700;
    line-height: 42px;
  } @else if($TYPE == $H4_R) {
    font-size: 24px;
    line-height: 32px;
  } @else if($TYPE == $H4_B) {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  } @else if($TYPE == $H5_R) {
    font-size: 20px;
    line-height: 26px;
  } @else if($TYPE == $H5_M) {
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
  } @else if($TYPE == $H5_B) {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
  } @else if($TYPE == $B1_M) {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  } @else if($TYPE == $B1_B) {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  } @else if($TYPE == $B1P_M) {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
  } @else if($TYPE == $B1P_B) {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
  } @else if($TYPE == $B2_M) {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  } @else if($TYPE == $B2_B) {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  } @else if($TYPE == $B2P_R) {
    font-size: 16px;
    line-height: 26px;
  } @else if($TYPE == $B2P_M) {
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
  } @else if($TYPE == $B2P_B) {
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
  } @else if($TYPE == $B3_M) {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  } @else if($TYPE == $B3_B) {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
  } @else if($TYPE == $B3P_M) {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  } @else if($TYPE == $B3P_B) {
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
  } @else if($TYPE == $B4_R) {
    font-size: 12px;
    line-height: 16px;
  } @else if($TYPE == $B4_M) {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  } @else if($TYPE == $B4_B) {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
  } @else if($TYPE == $B4P_M) {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
  } @else if($TYPE == $B4P_B) {
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
  }
}

// 스마트 에디터용 태그 스타일
@mixin smartEditorStyle {
  font-family: '맑은 고딕', '돋움', Malgun Gothic, Dotum, Helvetica, Sans-serif;
  line-height: 1.5;
  word-wrap: break-word;

  h1 {
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  h2 {
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  h3 {
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  h4 {
    font-size: 1em;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  h5 {
    font-size: 0.83em;
    margin-block-start: 1.67em;
    margin-block-end: 1.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  h6 {
    font-size: 0.67em;
    margin-block-start: 2.33em;
    margin-block-end: 2.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  p {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  em {
    font-style: italic;
  }

  ol {
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  ul {
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  li {
    display: list-item;
    text-align: -webkit-match-parent;

    &:before {
      unicode-bidi: isolate;
      font-variant-numeric: tabular-nums;
      text-transform: none;
      text-indent: 0px !important;
      text-align: start !important;
      text-align-last: start !important;
    }
  }

  a {
    text-decoration: underline;
  }

  button {
    appearance: auto;
    writing-mode: horizontal-tb !important;
    text-rendering: auto;
    color: buttontext;
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: center;
    align-items: flex-start;
    cursor: default;
    box-sizing: border-box;
    background-color: buttonface;
    margin: 0em;
    padding: 1px 6px;
    border-width: 2px;
    border-style: outset;
    border-color: buttonborder;
    border-image: initial;
  }
}


@mixin Flex($dir: row, $justify: flex-start, $align: stretch, $wrap: nowrap, $gap: 0) {
  display: flex;
  flex-direction: $dir;
  justify-content: $justify;
  align-items: $align;
  flex-wrap: $wrap;
  gap: $gap;
}