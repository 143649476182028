@import '../commonStyle.module.scss';

.wrap {
  padding-bottom: 94px;
}

.inner {
  gap: 24px;
}

.title {
  font-family: $F_RIX;
  font-size: 24px;
  font-weight: 400;
  line-height: 100%;
  color: $C_WHITE;
}

// qna 리스트 영역
.qnaList {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;

  .listItem {
    display: flex;
    flex-direction: column;
    width: 328px;
    gap: 6px;
    padding: 24px;
    border: 1px solid $C_COOL_GRAY_80;
    border-radius: 16px;
    text-align: left;
  }

  .question {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: $C_WHITE;
  }

  .answer {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.12px;
    color: $C_COOL_GRAY_60;
  }
}

// 유의사항 영역
.noticeArea {
  display: flex;
  flex-direction: column;
  width: 328px;
  text-align: left;
  margin: 0 auto;
}

.noticeTitle {
  flex: none;
  width: 260px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: $C_WHITE;
  margin-bottom: 16px;
}

.noticeInfoArea {
  flex: none;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.noticeInfoInner {
  position: relative;
  padding-left: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.12px;
  color: $C_COOL_GRAY_60;

  &:before {
    position: absolute;
    top: 6px;
    left: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $C_COOL_GRAY_60;
    content: '';
  }
}
