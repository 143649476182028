@import '../commonStyle.module.scss';

.commonBox {
  padding: 64px 0;
  background-color: $C_COOL_GRAY_30;

  &::after {
    content: '';
    margin-top: 40px;
    width: 360px;
    height: 638px;
    background-image: url(../@assets/profileZip.png);
    background-size: cover;
  }

  .subText {
    margin-top: 24px;
    font-size: 14px;
    font-weight: 500;
    color: $C_COOL_GRAY_70;
  }

  .btnBasic {
    margin-top: 26px;
    @include fontStyle($B2_M, $C_JOBDA_BLACK);

    .title {
      font-size: 14px;

      &::before {
        background-color: $C_JOBDA_BLACK;
      }

      .import {
        font-weight: 700;
      }
    }
  }
}
