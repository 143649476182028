@import '../index.module.scss';

.wrapper {
  position: relative;
  background: $V2C_GRAY_25;
  overflow: hidden;
}

.mainVisual {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px $space_L 80px $space_L;
  gap: $space_L;

  .mainInner {
    .mainTitle {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 12px;
      overflow: hidden;

      .mainTitle2 {
        margin-top: $space_L;
        text-align: center;
      }
    }
  }

  .mainTitle3 {
    text-align: center;
  }

  .titleList {
    position: relative;
    width: 160px;
    height: 56px;
    text-align: center;
    background: $V2C_GRAY_800;
    border-radius: 12px;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 160px;
      height: 56px;
      background: url('../img/edge.png') no-repeat center center / contain;
      z-index: 99;
    }

    .titleItem {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 56px;
      padding: 9px 20px;
      will-change: transform, opacity;
      white-space: nowrap;
      transition: transform 0.5s ease-out, opacity 0.5s ease-out;
      font-size: 28px;
      font-weight: 700;
      line-height: 38px;
      opacity: 0;
      transform: translateY(100%);

      &:first-child {
        opacity: 1;
        transform: translateY(0);
      }

      &:nth-child(1) {
        z-index: 6;
      }
      &:nth-child(2) {
        z-index: 5;
      }
      &:nth-child(3) {
        z-index: 4;
      }
      &:nth-child(4) {
        z-index: 3;
      }
      &:nth-child(5) {
        z-index: 2;
      }
      &:nth-child(6) {
        z-index: 1;
      }
    }
  }

  .visualWrap {
    position: relative;
    width: 220px;
    height: 250px;

    .box {
      position: absolute;
      width: 220px;
      height: 250px;
      transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.6s ease;
      will-change: transform, opacity;

      .title {
        display: none;
      }
    }

    .box1 {
      background-image: url('../img/imgCompany1.png');
      background-size: cover;
      background-position: center;
      transform: translateY(0);
    }
    .box2 {
      background-image: url('../img/imgCompany2.png');
      background-size: cover;
      background-position: center;
      transform: translateY(23px);
    }
    .box3 {
      background-image: url('../img/imgCompany3.png');
      background-size: cover;
      background-position: center;
      transform: translateY(46px);
    }
    .box4 {
      background-image: url('../img/imgCompany4.png');
      background-size: cover;
      background-position: center;
      transform: translateY(69px);
    }
    .box5 {
      background-image: url('../img/imgCompany5.png');
      background-size: cover;
      background-position: center;
      transform: translateY(92px);
    }
    .box6 {
      background-image: url('../img/imgCompany6.png');
      background-size: cover;
      background-position: center;
      transform: translateY(115px);
    }
  }
}
