@import '../commonStyle.module.scss';

.wrap {
  height: calc(100vh - 56px);
  padding: 0;
  background-color: initial;
  background-image: url(../@assets/bgIntro.png);
  background-size: cover;
  background-position: center;
}
