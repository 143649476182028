@import '../../styles/base.scss';
@import '../../styles/common.scss';
@import '../../styles/font.scss';
@import '../../styles/mixins.scss';
@import '../../styles/reset.scss';

$COMPONENT_IMG_PATH: '@assets';
$FADEIN_DURATION: 0.5s;
$MOBILE_SCREEN: 768px;

.sectionInner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0;
  transform: translateY(24px);
  transition: transform $FADEIN_DURATION ease-in-out,
    opacity $FADEIN_DURATION ease-in-out;
  opacity: 0;

  &.grayBg {
    background-color: #f9f9fa;
  }

  &.on {
    transform: translateY(0);
    opacity: 1;
  }
}

/* 공통 폰트 */
.mainTitle {
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.28px;
  color: #121619;
  text-align: center;

  .mainSmall {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.2px;
  }
}

.subTitle {
  margin: 16px 0 40px 0;
  color: #44474b;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.16px;
}
