@import '../commonStyle.module.scss';

// 미션 리스트 영역
.missionList {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  .listItem {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 328px;
    border-radius: 16px;
    background-color: $C_WHITE;

    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 1px solid $C_COOL_GRAY_40;
      border-radius: 16px;
      content: '';
    }
  }

  .itemInner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    text-align: left;
    z-index: 1;
  }

  .itemTitle {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.2px;
  }

  .itemDesc {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    color: $C_COOL_GRAY_70;
    text-align: center;
  }

  .btnEvent {
    position: relative;
    justify-content: center;
    width: 280px;
    height: 38px;
    margin: 24px 0 auto;
    padding: 0 17px 0 23px;

    svg {
      position: absolute;
      right: 14px;
      transform: rotate(180deg);
    }
  }

  .imgArea {
    flex: none;
    width: 328px;
    height: 194px;
    border-radius: 0 0 16px 16px;
    background-color: $C_COOL_GRAY_30;
    background-size: cover;

    @for $i from 1 through 2 {
      &.type#{$i} {
        background-image: url(../@assets/m_sec1_img#{$i}.png);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}
