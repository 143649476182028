@import '../detail.module.scss';

.detailInfoInner {
  // 설명 이미지
  &:after {
    width: 328px;
    height: 184px;
    margin: 0 auto;
    background-image: url(../../@assets/imgExplanation.png);
  }
}
