@import '../index.module.scss';

.wrapper {
  position: relative;
  @include Flex($dir: column, $align: center, $justify: flex-start);
  text-align: center;
  padding: 80px $space_L;
  overflow: hidden;
  background: $V2C_GRAY_25;
}

.badge {
  @include Flex($align: center, $justify: center, $gap: $space_XXXS);
  height: 36px;
  padding: 0 $space_L;
  margin-bottom: $space_L;
  background: $V2C_GRAY_100;
  border-radius: $radius_CIRCULAR;
  border: 1px solid #DFE0E2;
}

.videoWrapper {
  border: 1px solid rgba(223, 224, 226, 0.4);
  background: rgba(255, 255, 255, 0.54);
  margin-top: $space_XXXXL;

  video {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
  }
}
