@import '../matchIntroduce.module.scss';

.commonBox {
  overflow: hidden;
  padding: 96px 0 64px;

  .btnBlack {
    margin: 48px 0 64px;
  }
}
