@charset 'UTF-8';

// variables
@import './variables/colors.scss';
@import './variables/path.scss';
@import './variables/variables.scss';
@import './variables/fontType.scss';

// v2
@import './v2/variables/space.scss';
@import './v2/variables/radius.scss';
@import './v2/variables/opacity.scss';
@import './v2/variables/colors.scss';
@import './v2/variables/font.scss';
@import './v2/variables/borderColor.scss';
@import './v2/variables/backgroundColor.scss';
@import './v2/variables/fillStyle.scss';
@import './v2/variables/textColor.scss';

// mixins
@import './mixins.scss';

// hidden (대체 텍스트)
.hidden {
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
}

// 역량검사 강조
.textEmphasis {
  display: inline-block;
  font-family: $F_WEMAKE_PRICE;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  color: $C_SKY_200;
  vertical-align: 2px;
}
