@import '../commonStyle.module.scss';
$COMPONENT_IMG_PATH: $STATIC_IMG_PATH + '/pass/interview';

.banner {
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 48px;
  width: 100%;
  height: fit-content;
  background: url(../@assets/mainBg.png);
  background-size: cover;
  background-position: center;
  overflow: hidden;

  /* &::before {
    content: '';
    position: absolute;
    top: -25%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    width: 100%;
    height: 500px;
    opacity: 0.72;
    background: $C_GREEN_90;
    filter: blur(200px);
  } */

  .contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: $CONTENTS_WIDTH;
    z-index: 1;

    &::after {
      content: '';
      display: flex;
      width: 343px;
      height: 343px;
      margin-top: 24px;
      background-image: url(../@assets/imgBanner.png);
      background-position: center;
      background-size: cover;
    }

    .titleWrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $C_JOBDA_BLACK;

      .desc {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: $C_JOBDA_BLACK;

        .import {
          font-weight: bold;
        }
      }

      .title {
        margin-top: 8px;
        text-align: center;
        font-size: 28px;
        font-weight: 700;
        line-height: 36px;
        color: $C_JOBDA_BLACK;

        strong {
          margin-top: 16px;
          letter-spacing: 0;
        }
      }
    }

    .btnWrap {
      display: flex;
      gap: 12px;
      margin-top: 64px;
    }
  }
}
