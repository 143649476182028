@import '../accaIntro.module.scss';

.flowInner {
  overflow: hidden;
  width: 100%;

  .mainTitle {
    margin-bottom: 40px;
  }
}
