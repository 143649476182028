@import '../commonStyle.module.scss';

.commonBox {
  padding: 64px 0;
  background-image: url(../@assets/introBg.png);
  background-size: cover;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;

  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 700;
    color: $C_JOBDA_BLACK;

    &::before {
      content: '';
      display: block;
      margin-bottom: 16px;
      width: 182px;
      height: 182px;
      background-image: url(../@assets/banner2.png);
      background-size: cover;
      background-position: center;
    }
  }
}
