@import '../commonStyle.module.scss';

.introBox {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100vh - 56px);
  background-color: #27355b;
  background-image: url(../@assets/mainBg.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
