@import '../index.module.scss';

.textVisualWrapper {
  height: 300vh;
  position: relative;
  background-color: white;
  overflow: hidden;
}

.textVisualContent {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.textImg {
  width: 100px;
  height: 60px;
  background: url('./text.svg') no-repeat center center / contain;
}

@media (min-width: 768px) {
  .container {
    padding: 0 40px;
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1400px;
  }
}
