@import '../commonStyle.module.scss';
$COMPONENT_IMG_PATH: $STATIC_IMG_PATH + '/pass/interview';
$FADEIN_DURATION: 1.2s;

.listWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 343px;
  gap: 16px;

  .list {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 40px 24px;
    width: 100%;
    height: 400px;
    border-radius: 24px;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100px;
      background: linear-gradient(
        0deg,
        rgba(75, 214, 103, 0.6) 0%,
        rgba(75, 214, 103, 0) 100%
      );
    }

    .title {
      @include fontStyle($H5_B, $C_JOBDA_WHITE);
    }

    .subTitle {
      margin-top: 8px;
      font-size: 16px;
      font-weight: 600;
      line-height: 26px;
      color: $C_JOBDA_WHITE;
    }

    &:nth-child(1) {
      overflow: hidden;
      background: $C_GREEN_200;

      .logoList {
        position: absolute;
        bottom: -50px;
        left: 0;

        .logoFlow {
          position: relative;
          display: flex;
          flex-direction: column;
        }

        .logoImg {
          position: absolute;
          left: 0;
          animation-duration: 110s;
          animation-iteration-count: infinite;
          animation-name: flow-ani;
          animation-timing-function: linear;
          background-position: 0 0;
          background-repeat: repeat-x;
          height: 130px;

          &:first-child {
            bottom: 170px;
            background-image: url(../@assets/logo1.png);
            background-size: 4704px 130px;
            width: 4704px;
          }

          &:nth-child(2) {
            bottom: 70px;
            animation-duration: 100s;
            animation-name: rev-flow-ani;
            background-image: url(../@assets/logo2.png);
            background-size: 4116px 130px;
            width: 4116px;
          }

          &:nth-child(3) {
            bottom: -35px;
            background-image: url(../@assets/logo3.png);
            background-size: 4704px 130px;
            width: 4704px;
          }

          @keyframes flow-ani {
            0% {
              background-position: 0 0;
            }
            100% {
              background-position: 4704px 0;
            }
          }

          @keyframes rev-flow-ani {
            0% {
              background-position: 0 0;
            }
            100% {
              background-position: -4116px 0;
            }
          }
        }
      }
    }

    &:nth-child(2) {
      overflow: hidden;
      background: $C_GREEN_100;

      .questionList {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 24px;

        &.on {
          .question {
            animation: fadeInMoveToLeft 0.5s ease-out forwards;
          }
        }

        .question {
          position: absolute;
          width: 450px;
          height: 132px;
          background-size: cover;
          border-radius: 16px;
          opacity: 0;
          background-position: -20px;

          &:nth-child(1) {
            top: 0;
            left: 0;
            background-image: url(../@assets/question1.png);
            animation-duration: 0.3s;
            animation-delay: 0s;
          }

          @for $i from 2 through 3 {
            &:nth-child(#{$i}) {
              top: 88px + ($i - 2) * 88px;
              left: 24px + ($i - 2) * 24px;
              background-image: url('../@assets/question' + #{$i} + '.png');
              animation-delay: 0.4s * ($i - 1);
            }
          }
        }
      }

      @keyframes fadeInMoveToLeft {
        from {
          left: 24px;
          opacity: 0;
        }
        to {
          left: 0;
          opacity: 1;
        }
      }
    }

    &:nth-child(3) {
      overflow: hidden;
      background: $C_GREEN_200;

      .title {
        position: relative;

        &::after {
          content: 'NEW';
          position: absolute;
          top: -2px;
          left: 150px;
          font-size: 12px;
          color: $C_YELLOW_100;
        }
      }

      .contentTitle {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: 108px;
          left: -20px;
          width: 480px;
          height: 525px;
          background: url(../@assets/questionAcca.png);
          background-size: cover;
          background-position: center;
          opacity: 0;
          transform: translateY(15%);
          transition: transform $FADEIN_DURATION ease-out,
            opacity $FADEIN_DURATION ease-out;
        }

        &.on {
          &::after {
            transform: translateY(0);
            opacity: 1;
          }
        }
      }
    }

    &:nth-child(4) {
      position: relative;
      overflow: hidden;
      background: $C_GREEN_100;

      &::after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -100px;
        width: 345px;
        height: 340px;
        background: url(../@assets/questionMade.png);
        background-size: cover;
        background-position: center;
      }
      .questionMade {
        position: absolute;
        top: 192px;
        left: 60px;
        font-size: 12px;
        font-weight: 500;
        color: $C_COOL_GRAY_90;
        z-index: 1;

        .blinking-cursor {
          animation: blinkCursor 0.8s infinite;
        }

        @keyframes blinkCursor {
          from {
            opacity: 1;
          }
          to {
            opacity: 0;
          }
        }
      }
    }
  }
}
