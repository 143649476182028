@import '../../styles/base.scss';
@import '../../styles/common.scss';
@import '../../styles/font.scss';
@import '../../styles/mixins.scss';
@import '../../styles/reset.scss';

/* 모바일 폰트 */
.body {
  font-family: $F_PRETENDARD !important;
}

// 전체 감싸는 요소
.wrap {
  padding-top: 56px;
  background-color: $C_JOBDA_BLACK;
  text-align: center;
}

// 내부 inner 영역
.inner {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 56px;
  width: 100%;
  margin: 0 auto;
}

// 공통 섹션 타이틀(좌우에 아이콘 달린 형태)
.sectionTitleArea {
  display: flex;
  align-items: center;
  justify-content: center;

  .sectionTitle {
    flex: none;
    font-family: $F_RIX;
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    color: $C_WHITE;
  }

  .emphasis {
    color: #00ff9d;
  }
}
