@import '../commonStyle.module.scss';

.giftInner {
  background: $C_JOBDA_BLACK;

  .star {
    margin-bottom: 32px;
  }

  .eventTitle {
    .imgTitle {
      width: 271px;
      height: 131px;
      background-image: url(../@assets/giftTitle.png);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    .titleText {
      display: inline-block;
      margin-top: 8px;
      @include fontStyle($H3_B, $C_WHITE);
    }
  }

  .btnGreen {
    margin-top: 40px;
  }

  .desc {
    width: 328px;
    margin-top: 72px;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.12px;
    color: $C_COOL_GRAY_60;
    text-align: left;
  }
}

/* 혜택 리스트 */
.giftList {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 72px;
  width: 360px;
  margin-top: 40px;
  border-top: 1px solid #44474b;

  .giftBox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .btnClick {
      margin: 31px 0 16px 0;
      width: 272px;
      height: 48px;
    }

    .btnView {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: -0.14px;
      color: $C_COOL_GRAY_50;

      .btnText {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -5px;
          width: 100%;
          height: 1px;
          background-color: $C_COOL_GRAY_50;
        }
      }
    }
  }
}

.giftImg {
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;

  &.giftImg1 {
    width: 330px;
    height: 188px;
    background-image: url(../@assets/Npay.png);
  }

  &.giftImg2 {
    width: 180px;
    height: 255px;
    background-image: url(../@assets/zipBook.png);
  }

  &.giftImg3 {
    width: 180px;
    height: 255px;
    background-image: url(../@assets/accaBook.png);
  }
}
