@import '../commonStyle.module.scss';

.commonBox {
  padding: 64px 0 112px;
  background-color: $C_JOBDA_BLACK;

  .desc {
    text-align: center;
    background: linear-gradient(90deg, #4cd668 0%, #4cced6 100%);
    background-clip: text;
    text-fill-color: transparent;
    font-size: 18px;
    font-weight: 500;
    color: transparent;
    line-height: 24px;

    &::before {
      content: '';
      display: block;
      margin: 0 auto 56px;
      width: 212px;
      height: 50px;
      background-image: url(../@assets/endingTalk.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .import {
    margin-top: 8px;
    text-align: center;
    background: linear-gradient(90deg, #4cd668 0%, #4cced6 100%);
    background-clip: text;
    text-fill-color: transparent;
    font-size: 24px;
    font-weight: 700;
    color: transparent;
    line-height: 32px;
  }

  .btnMove {
    display: flex;
    align-items: center;
    margin-top: 40px;
    padding: 0 28px 0 40px;
    height: 54px;
    border-radius: 27px;
    background-color: $C_WHITE;
    font-size: 14px;
    font-weight: 700;
    color: $C_JOBDA_BLACK;

    &:hover {
      background-color: $C_COOL_GRAY_30;
    }

    svg {
      margin-left: 4px;
      transform: rotate(90deg);
    }
  }
}
