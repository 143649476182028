
.wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.divider {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid;
  border-color: #e7e8ea;

  .barWrap {
    position: relative;
    width: 100%;

    .bar {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 30px;
      background-color: #fff;
      border: 1px solid #44474b;
      border-bottom: 0;
      border-radius: 8px 8px 0 0;
      transform: translate(-50%);
      z-index: 1;

      &.small {
        width: 30px;
        left: 50%;
        border-radius: 6px 6px 0 0;

        .tooltip {
          top: -4px;
          min-width: 38px;
          max-width: 58px;
          padding: 5px 8px;
          font-size: 10px;
          line-height: 12px;
        }
      }

      .tooltip {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -8.5px;
        width: fit-content;
        padding: 6px 8px;
        background-color: #fff;
        border: 1px solid #cfd1d5;
        border-radius: 4px;
        font-family: 'Pretendard', sans-serif;
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: -0.01em;
        color: #6a6a6a;
        white-space: break-spaces;
        transform: translateY(-100%);
        text-align: center;
      }

      &.reverse {
        transform: translate(-50%, calc(100% + 1px));
        border: 1px solid #44474b;
        border-top: 0;
        border-radius: 0 0 8px 8px;

        &.small {
          border: 1px solid #44474b;
          border-top: 0;
          border-radius: 0 0 6px 6px;
        }
      }
    }

    &:last-child {

      .bar {
        border: 1px solid #121619;
        background-color: #121619;

        &.small {

          .tooltip {
            top: -9px;
            min-width: 50px;
            max-width: 68px;
            padding: 6px 8px;
            .subText {
              display: flex;
              align-items: center;
              top: calc(-50% - 10px);
              font-size: 10px;
            }
          }
        }
      }

      .tooltip {
        top: -13.5px;
        padding: 8px 16px;
        border-color: #121619;
        background-color: #121619;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #fff;
        .subText {
          position: absolute;
          top: calc(-50% - 4px);
          font-family: 'Pretendard', sans-serif;
          font-weight: 700;
          font-size: 12px;
          line-height: 14px;
          color: #121619;
          white-space: nowrap;
        }

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 100%);
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 7px solid #121619;
        }
      }
    }
  }

  &.on {
    border-color: #44474b;

    &::after {
      content: '';
      position: absolute;
      right: calc(100% + 32px);
      bottom: -9px;
      font-family: 'Pretendard', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
    }

    &.small {
      border-color: #9fa4ab;

      &::after {
        right: calc(100% + 8px);
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}

.rowWrap {
  display: flex;
  width: 100%;
  transform: translateY(calc(100% + 12px));

  &.small {
    transform: translateY(calc(100% + 8px));

    .row {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .row {
    width: 100%;
    text-align: center;
    font-family: 'Pretendard', sans-serif;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: #6a6a6a;
    
    &:last-child {
      font-weight: 700;
      color: #121619;
    }
  }
}