@import '../detail.module.scss';

.detailInfoInner {
  // 경품 이미지
  &:after {
    width: 328px;
    height: 386px;
    margin: 0 auto;
    background-image: url(../../@assets/imgDetailGift.png);
  }
}
