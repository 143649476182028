@import '../commonStyle.module.scss';

.commonBox {
  padding: 64px 0 0 0;
  background-image: url(../@assets/bannerBg.png);
  background-size: cover;
  background-position: center;

  overflow: hidden;
  z-index: 1;

  .desc {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: $C_JOBDA_BLACK;
  }

  .reviewInner {
    position: relative;
    padding-bottom: 320px;

    .nameTag {
      position: absolute;
      bottom: -410px;
      left: -170px;
      width: 780px;
      height: 920px;
      background-image: url(../@assets/nameTag.png);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      z-index: -1;
    }
  }

  .review {
    margin-top: 40px;
    width: 320px;
    height: 170px;
    background-image: url(../@assets/review.png);
    background-size: cover;
    background-position: center;
  }

  .btnBasic {
    margin-top: 32px;
    font-size: 14px;
    font-weight: 500;
    color: $C_JOBDA_BLACK;

    &:hover {
      svg {
        path {
          fill: $C_JOBDA_BLACK;
        }
      }
    }

    .title {
      &::before {
        background-color: $C_JOBDA_BLACK;
      }
    }
  }
}
