@import '../../styles/base.scss';
@import '../../styles/common.scss';
@import '../../styles/font.scss';
@import '../../styles/mixins.scss';
@import '../../styles/reset.scss';

.infoHidden {
  display: none;
}

.commonBox {
  .footer {
    display: flex;
    align-items: center;
    margin-top: 40px;
    border-top: 1px solid #d1d2d4;
    height: 128px;

    .footerText {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      flex-direction: column;
      margin: 0 auto;
      padding-top: 23px;
      width: 100%;
      height: 100%;
      font-size: 14px;
      font-weight: 500;
      color: #838486;

      .call {
        display: block;
      }
    }
  }

  .before {
    display: flex;
    margin: 0 auto;
    width: 375px;
    height: 4284px;
    background-image: url(@assets/loginBefore.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}
