@import '../../styles/base.scss';
@import '../../styles/common.scss';
@import '../../styles/font.scss';
@import '../../styles/mixins.scss';
@import '../../styles/reset.scss';

.wrap {
  background-color: #27355b;
}

/* 공통 box */
.commonBox {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.banner3 {
  width: 100%;
  height: 423px;
  background-color: #27355b;
  background-image: url(@assets/lastBanner.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

/* 공통 폰트 */
.basicTitle {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .subTitle {
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    color: $C_JOBDA_BLACK;
  }

  .mainTitle {
    font-size: 28px;
    font-weight: 700;
    color: $C_JOBDA_BLACK;

    .import {
      color: $C_GREEN_150;
    }
  }
}

/* 기본 underline 버튼 */
.btnBasic {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  font-size: 14px;
  font-weight: 500;
  color: $C_COOL_GRAY_40;

  &:hover {
    color: $C_COOL_GRAY_60;

    .title {
      &::before {
        background-color: $C_COOL_GRAY_60;
      }
    }

    svg {
      path {
        fill: $C_COOL_GRAY_60;
      }
    }
  }

  .title {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $C_COOL_GRAY_50;
    }
  }
}
