@import '../commonStyle.module.scss';

.commonBox {
  padding: 80px 0 64px 0;
  background: linear-gradient(180deg, $C_JOBDA_WHITE 0%, $C_COOL_GRAY_30 100%);

  .basicTitle {
    text-align: center;
    gap: 0;

    .mainTitle {
      margin-top: 12px;
    }

    &::after {
      content: '';
      display: block;
      margin: 40px auto 0;
      width: 360px;
      height: 886px;
      background-image: url(../@assets/profile1.png);
      background-size: cover;
    }
  }

  .btnBasic {
    margin: 24px auto 0;
    color: $C_JOBDA_BLACK;

    .title {
      &::before {
        background-color: $C_JOBDA_BLACK;
      }
    }
  }
}

/* 이미지 타이틀 */
.star {
  width: 23px;
  height: 23px;
  background-image: url(../@assets/partDot.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.mainDesc {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .icon {
    width: 64px;
    height: 64px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &.folder {
      background-image: url(../@assets/icon_file_green.png);
    }
  }

  .text {
    margin-top: 16px;
    font-size: 18px;
    font-weight: 500;
    color: $C_JOBDA_BLACK;
  }

  .desc {
    position: relative;
    margin-top: 10px;
    @include fontStyle($B1_B, $C_GREEN_200);

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $C_GREEN_200;
    }
  }

  .star {
    margin: 24px 0;
  }
}
