// GRAY
$V2C_GRAY_WHITE: #ffffff;
$V2C_GRAY_BLACK: #000000;
$V2C_GRAY_25: #f7f7f7;
$V2C_GRAY_50: #f3f3f7;
$V2C_GRAY_100: #e9eaeb;
$V2C_GRAY_200: #dfe0e2;
$V2C_GRAY_300: #d1d2d4;
$V2C_GRAY_400: #acaeb0;
$V2C_GRAY_500: #838486;
$V2C_GRAY_600: #464748;
$V2C_GRAY_700: #333436;
$V2C_GRAY_800: #18191b;

// GREEN
$V2C_GREEN_25: #e5fff0;
$V2C_GREEN_50: #d7f9e3;
$V2C_GREEN_100: #c2f4d3;
$V2C_GREEN_200: #79e7a2;
$V2C_GREEN_300: #3ee089;
$V2C_GREEN_400: #02d46a;
$V2C_GREEN_500: #02c551;
$V2C_GREEN_600: #00b840;
$V2C_GREEN_700: #02a73c;
$V2C_GREEN_800: #008c2c;

// SPRING GREEN
$V2C_SPRINGGREEN_25: #effbed;
$V2C_SPRINGGREEN_50: #e1f7dc;
$V2C_SPRINGGREEN_100: #c6efbd;
$V2C_SPRINGGREEN_200: #a2e594;
$V2C_SPRINGGREEN_300: #85dd74;
$V2C_SPRINGGREEN_400: #67d551;
$V2C_SPRINGGREEN_500: #5dcd47;
$V2C_SPRINGGREEN_600: #52b13f;
$V2C_SPRINGGREEN_700: #50a140;
$V2C_SPRINGGREEN_800: #428f33;

// OLIVEGREEN
$V2C_OLIVEGREEN_25: #f7fbeb;
$V2C_OLIVEGREEN_50: #eff6d7;
$V2C_OLIVEGREEN_100: #e2eeb9;
$V2C_OLIVEGREEN_200: #cae17f;
$V2C_OLIVEGREEN_300: #bdd95f;
$V2C_OLIVEGREEN_400: #acd037;
$V2C_OLIVEGREEN_500: #a3c62f;
$V2C_OLIVEGREEN_600: #8ead2a;
$V2C_OLIVEGREEN_700: #7e9a23;
$V2C_OLIVEGREEN_800: #708820;

// YELLOW
$V2C_YELLOW_25: #fffadb;
$V2C_YELLOW_50: #fff6bd;
$V2C_YELLOW_100: #fff18f;
$V2C_YELLOW_200: #ffe566;
$V2C_YELLOW_300: #ffdb4d;
$V2C_YELLOW_400: #ffcf0f;
$V2C_YELLOW_500: #f5c000;
$V2C_YELLOW_600: #e0a500;
$V2C_YELLOW_700: #c79200;
$V2C_YELLOW_800: #b28000;

//GOLD
$V2C_GOLD_25: #fbf7eb;
$V2C_GOLD_50: #f8eed6;
$V2C_GOLD_100: #f2e0b5;
$V2C_GOLD_200: #eacc80;
$V2C_GOLD_300: #e4bc5a;
$V2C_GOLD_400: #ddab31;
$V2C_GOLD_500: #d0a02a;
$V2C_GOLD_600: #bc9024;
$V2C_GOLD_700: #ad821b;
$V2C_GOLD_800: #9c7517;

// ORANGE
$V2C_ORANGE_25: #fff5f0;
$V2C_ORANGE_50: #fee5d9;
$V2C_ORANGE_100: #fed6c3;
$V2C_ORANGE_200: #fdb28c;
$V2C_ORANGE_300: #fd9966;
$V2C_ORANGE_400: #ff7628;
$V2C_ORANGE_500: #f46b1d;
$V2C_ORANGE_600: #e95907;
$V2C_ORANGE_700: #c74800;
$V2C_ORANGE_800: #ad3f00;

// RED
$V2C_RED_25: #fff5f5;
$V2C_RED_50: #ffe7e5;
$V2C_RED_100: #ffd8d6;
$V2C_RED_200: #ff9e99;
$V2C_RED_300: #fd8882;
$V2C_RED_400: #fc675f;
$V2C_RED_500: #fd493f;
$V2C_RED_600: #f1392c;
$V2C_RED_700: #e42a20;
$V2C_RED_800: #cb2620;

// PINK
$V2C_PINK_25: #fff0f3;
$V2C_PINK_50: #fedbe4;
$V2C_PINK_100: #fec8d6;
$V2C_PINK_200: #fd93af;
$V2C_PINK_300: #fd6f95;
$V2C_PINK_400: #fc4b7a;
$V2C_PINK_500: #ef2e62;
$V2C_PINK_600: #e42558;
$V2C_PINK_700: #c61e4b;
$V2C_PINK_800: #b0274b;

// PURPLE
$V2C_PURPLE_25: #f9f0f8;
$V2C_PURPLE_50: #f1daed;
$V2C_PURPLE_100: #ebcbe6;
$V2C_PURPLE_200: #d590c9;
$V2C_PURPLE_300: #c76bb7;
$V2C_PURPLE_400: #b946a5;
$V2C_PURPLE_500: #ad3e99;
$V2C_PURPLE_600: #a1368e;
$V2C_PURPLE_700: #942b82;
$V2C_PURPLE_800: #832372;

// VIOLET
$V2C_VIOLET_25: #f2f1fe;
$V2C_VIOLET_50: #dfdcfd;
$V2C_VIOLET_100: #d0cbfb;
$V2C_VIOLET_200: #a7a0ef;
$V2C_VIOLET_300: #8b82e9;
$V2C_VIOLET_400: #6f64dd;
$V2C_VIOLET_500: #6358d5;
$V2C_VIOLET_600: #574ccd;
$V2C_VIOLET_700: #4a40b5;
$V2C_VIOLET_800: #3f369a;

// COBALT BLUE
$V2C_COBALTBLUE_25: #eef5fb;
$V2C_COBALTBLUE_50: #e0eeff;
$V2C_COBALTBLUE_100: #cce2ff;
$V2C_COBALTBLUE_200: #93bbf6;
$V2C_COBALTBLUE_300: #3982ef;
$V2C_COBALTBLUE_400: #376cf2;
$V2C_COBALTBLUE_500: #1e5af0;
$V2C_COBALTBLUE_600: #174fde;
$V2C_COBALTBLUE_700: #003ec2;
$V2C_COBALTBLUE_800: #0031ad;

// SKY BLUE
$V2C_SKYBLUE_25: #f0f9ff;
$V2C_SKYBLUE_50: #e1f4ff;
$V2C_SKYBLUE_100: #ccecff;
$V2C_SKYBLUE_200: #83d1ff;
$V2C_SKYBLUE_300: #52c0ff;
$V2C_SKYBLUE_400: #33b2fe;
$V2C_SKYBLUE_500: #2ca4fb;
$V2C_SKYBLUE_600: #2497f3;
$V2C_SKYBLUE_700: #1084f2;
$V2C_SKYBLUE_800: #0b6cd5;

// CYAN
$V2C_CYAN_25: #e9fafb;
$V2C_CYAN_50: #ccf3f7;
$V2C_CYAN_100: #b5eef3;
$V2C_CYAN_200: #67dbe6;
$V2C_CYAN_300: #34cfdd;
$V2C_CYAN_400: #01c3d5;
$V2C_CYAN_500: #00b6c7;
$V2C_CYAN_600: #009fad;
$V2C_CYAN_700: #00909d;
$V2C_CYAN_800: #007984;

// TEAL
$V2C_TEAL_25: #e6fcff;
$V2C_TEAL_50: #d5fbee;
$V2C_TEAL_100: #bcf4e4;
$V2C_TEAL_200: #63e6be;
$V2C_TEAL_300: #38d9a9;
$V2C_TEAL_400: #20c997;
$V2C_TEAL_500: #12b886;
$V2C_TEAL_600: #0ca678;
$V2C_TEAL_700: #099268;
$V2C_TEAL_800: #087f5b;
