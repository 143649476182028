@import '../../matchIntroduce.module.scss';

.logoList {
  margin: 60px 0 64px;
  width: 100%;
  overflow: hidden;

  .logoImg:not(:last-of-type) {
    margin-bottom: 8px;
  }

  .logoImg {
    animation-duration: 120s;
    animation-iteration-count: infinite;
    animation-name: flow-ani;
    animation-timing-function: linear;
    background-position: 0 0;
    background-repeat: repeat-x;
    height: 50px;

    &:first-child {
      background-image: url(../../@assets/logo_1.png);
      background-size: 1884px 50px;
      width: 3770px;
    }

    &:nth-child(2) {
      animation-duration: 110s;
      animation-name: rev-flow-ani;
      background-image: url(../../@assets/logo_2.png);
      background-size: 1884px 50px;
      width: 5076px;
    }
    &:nth-child(3) {
      background-image: url(../../@assets/logo_3.png);
      background-size: 1884px 50px;
      margin-left: -40px;
      width: 3770px;
    }

    @keyframes flow-ani {
      0% {
        background-position: 0 0;
      }
      100% {
        background-position: 3770px 0;
      }
    }

    @keyframes rev-flow-ani {
      0% {
        background-position: 0 0;
      }
      100% {
        background-position: -5076px 0;
      }
    }
  }
}
