@import '../accaIntro.module.scss';

.boxInner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 40px;
  padding: 0 16px;
  width: 100%;
  gap: 40px;

  .box {
    display: flex;
    flex-direction: column;
    background: #f9f9fa;
    border-radius: 16px;

    .boxImg {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    &.fullWidth {
      width: 328px;
      padding: 32px;

      .boxImg {
        margin-top: 40px;
        width: 264px;
        height: 464px;
      }
    }

    &.halfWidth {
      width: 328px;
      padding: 32px;

      .boxImg {
        margin-top: 40px;
        width: 264px;
        height: 264px;
      }
    }
  }

  .sliderInner {
    :global {
      .slick-slide {
        left: 0 !important;
      }

      .slick-track {
        width: 100% !important;
      }
    }
  }

  /* 폰트 */
  .title {
    @include fontStyle($H5_B, $C_JOBDA_BLACK);

    .titleLight {
      display: block;
      @include fontStyle($H5_B, $C_COOL_GRAY_60);
    }
  }

  .subTitle {
    position: relative;
    margin: 24px 0 0 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.14px;
  }

  .link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;

    .linkText {
      position: relative;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.12px;

      &::after {
        position: absolute;
        right: 0;
        bottom: -3px;
        left: 0;
        height: 1px;
        background-color: #44474b;
        content: '';
      }

      &:hover {
        color: $C_COOL_GRAY_80;

        &::after {
          background-color: $C_COOL_GRAY_80;
        }
      }
    }
  }
}
