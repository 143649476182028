@import '../commonStyle.module.scss';
.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: calc(100vh - 54px);
  background-color: $C_BLACK;
  background-image: url(../@assets/introMainBg.png);
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;

  &::before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 292px;
    height: 360px;
    background-image: url(../@assets/introTitle.png);
    background-size: cover;
    background-position: center;
  }

  .desc {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;

    &.giftDate {
      margin: 16px 0 100px 0;
    }

    .period {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      color: $C_WHITE;

      &::after {
        content: '';
        display: inline-block;
        margin-left: 12px;
        width: 1px;
        height: 18px;
        background-color: rgba($C_WHITE, 0.5);
      }

      &::before {
        content: '';
        display: inline-block;
        margin-right: 12px;
        width: 1px;
        height: 18px;
        background-color: rgba($C_WHITE, 0.5);
      }
    }

    .date {
      font-size: 16px;
      font-weight: 700;
      color: $C_WHITE;
    }
  }

  .subText {
    display: flex;
    align-items: center;
    margin-top: 30px;
    font-size: 18px;
    font-weight: 500;
    line-height: 160%;
    color: $C_JOBDA_BLACK;

    .descTitle {
      line-height: 24px;
    }

    .import {
      position: relative;
      margin-top: 12px;
      font-weight: 700;
      z-index: 0;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0px;
        display: inline-block;
        width: 118px;
        height: 12px;
        background: linear-gradient(90deg, #b3ffc2 0%, #b3edff 100%);
        z-index: -1;
      }
    }
  }
}
