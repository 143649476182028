@import '../commonStyle.module.scss';

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(51, 52, 54, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal {
  padding: 24px;
  width: 327px;
  height: auto;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 32px 64px -12px rgba(16, 24, 40, 0.14);
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 20px;
    font-weight: 600;
    color: #333436;
  }

  .subTitle {
    margin-top: 8px;
    color: #838486;
    font-size: 14px;
    font-weight: 500;
  }

  .code {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 24px;
    font-size: 14px;
    font-weight: 500;

    .inputCode {
      padding: 0 16px;
      width: 280px;
      height: 40px;
      border-radius: 6px;
      border: 1px solid #d1d2d4;
      font-size: 14px;
      font-weight: 500;

      &:focus {
        outline: none;
        border: 2px solid #12b886;
      }

      &::placeholder {
        color: #acaeb0;
      }

      &.error {
        border: 2px solid #fd493f;
      }
    }
  }

  /* 이용약관 */
  .agree {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 280px;
    cursor: pointer;

    .agreeCheck {
      display: none;

      & + label {
        position: relative;
        padding-left: 25px;
        cursor: pointer;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 16px;
          height: 16px;
          border-radius: 4px;
          border: 1px solid #ccc;
          background-color: #fff;
        }
      }

      &:checked {
        & + label::before {
          width: 16px;
          height: 16px;
          border: none;
          background-size: cover;
          background: url(../@assets/checkbox.svg) no-repeat;
        }
      }
    }

    .agreeLabel {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 500;
    }

    .agreeContent {
      margin: 16px 0 10px 0;
      padding: 10px 12px;
      border-radius: 6px;
      width: 100%;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: #838486;
      text-align: center;
      background-color: #f3f3f7;
    }
  }

  /* 버튼 */
  .btnBasic {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    padding: 0 16px;
    height: 40px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 6px;
    background: #20c997;
    color: #fff;
  }

  .enjoy {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 20px;
    color: #838486;
    font-size: 12px;
    font-weight: 500;

    .link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 125px;
      height: 32px;
      font-size: 14px;
      font-weight: 600;
      color: #333436;
      border-radius: 6px;
      border: 1px solid #d1d2d4;
      box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
        0px 1px 2px 0px rgba(16, 24, 40, 0.06);
    }
  }
}
