@import '../../../../styles/common.scss';

.wrap {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 99999px;
  border: 1px solid $color_border_default;

  &.m {
    width: 20px;
    height: 20px;

    &.selected {
      &::after {
        width: 12px;
        height: 12px;
      }
    }
  }

  &.selected {
    position: relative;
    @include V2FillStyle($color_fill_brand_strong_default);
    border: none;

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 15px;
      height: 15px;
      border-radius: 99999px;
      background-color: $color_background_surface_default;
      content: '';
    }
  }

  &:not(.selected, .disabled) {
    @include V2FillStyle($color_fill_neutral_subtle_default);
  }

  &.disabled {
    background-color: $color_background_disabled;
    border-color: $color_border_disabled;

    &.selected {
      background-color: $color_border_strong;
    }
  }
}
