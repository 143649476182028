@import '../commonStyle.module.scss';

.wrap {
  padding-bottom: 56px;
}

.inner {
  gap: 24px;
}

.sectionDesc {
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.14px;
  color: $C_COOL_GRAY_60;
}

// 기업 정보 영역
.companyListArea {
  width: 328px;
  margin: 0 auto;

  .companyListInner {
    :global {
      .swiper-container {
        display: flex;
        flex-direction: column;
      }
  
      .swiper-pagination {
        position: static;
        order: 2;
        align-items: center;
        margin-top: 16px;
  
        & > div {
          position: static;
          padding: 0;
          background-color: initial;
          
          .swiper-pagination-current {
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: -0.12px;
            color: $C_WHITE;
          }
          
          .bar {
            display: none;
          }
          
          .swiper-pagination-total {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: -0.12px;
            color: #44474b;

            &:before {
              flex: none;
              margin: 0 4px;
              content: '/';
            }
          }
        }
      }
  
      .swiper-wrapper {
        order: 1;
      }
    }
  }
}

// 테이블 내용 한줄씩 들어가는 영역
.dataInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 16px;
  border-radius: 4px;
  background-color: #25282b;

  & + .dataInner {
    margin-top: 10px;
  }
}

.dataWrap {
  overflow: hidden;
  display: flex;
  align-items: center;

  &:before {
    flex: none;
    width: 40px;
    height: 40px;
    margin-right: 12px;
    background-color: $C_WHITE;
    background-size: cover;
    content: '';
  }

  @for $i from 1 through 15 {
    &.logo#{$i} {
      &:before {
        background-image: url('../@assets/iconLogo#{$i}.png');
      }
    }
  }
}

.infoArea {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: left;
}

.companyName {
  @include ellipsis(1, 18px);

  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.14px;
  color: $C_WHITE;
}

.job {
  @include ellipsis(1, 16px);

  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.12px;
  color: $C_WHITE;
}

// 채용이벤트(챌린지)로 가는 링크
.link {
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 66px;
  height: 32px;
  margin-left: 8px;
  border-radius: 4px;
  background-color: #4021ff;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.12px;
  color: $C_WHITE;
}
