@import '../accaIntro.module.scss';

.introInner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .main_video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    mask-image: radial-gradient(white, black);
    backface-visibility: hidden;
    visibility: hidden;
  }
  .play {
    visibility: visible;
  }

  .introTitle {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 80px;
  }
}

/* 폰트 */
.main_text {
  @include fontStyle($H4_B, $C_BLACK);
}

.main_title {
  display: inline-block;
  color: $C_BLACK;
  font-weight: 700;
  text-align: center;
  margin-top: 24px;
  font-size: 34px;
  line-height: 44px;
  letter-spacing: -0.34px;
}
