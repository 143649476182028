@import '../commonStyle.module.scss';

.bannerWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  background: url(../@assets/bg.png);

  .banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .total {
      display: flex;
      align-items: center;
      margin-top: 20px;

      .lottieWrap {
        width: 56px;
        height: 56px;
      }

      .state {
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        color: #fff;
      }
    }

    /* 로고 */
    .logo {
      display: flex;
      justify-content: center;
      margin-top: 24px;
      width: 102px;
      height: 30px;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url(../@assets/logo.svg);
    }

    /* 비쥬얼 영역 */
    .visualWrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 44px;

      .visual {
        width: 320px;
        height: 192px;
        background: url(../@assets/visual.png) no-repeat;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }

      /* 타임 */
      .timeWrap {
        display: flex;
        flex-direction: column;
        margin-top: 80px;

        .timeInner {
          position: relative;
          display: flex;
          justify-content: center;
          gap: 8px;

          &::before {
            content: '';
            position: absolute;
            top: -40px;
            width: 128px;
            height: 24px;
            background: url(../@assets/title1.png) no-repeat;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
          }

          .time {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;

            .num {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 74px;
              height: 80px;
              border-radius: 8px;
              background: rgba(0, 0, 0, 0.8);
              color: $C_JOBDA_WHITE;
              font-size: 40px;
              font-weight: 400;
            }

            .unit {
              font-size: 14px;
              font-weight: 500;
              color: $C_JOBDA_WHITE;
            }
          }
        }
      }

      /* 안내사항 */
      .info {
        display: flex;
        flex-direction: column;
        margin: 40px 0;
        font-size: 12px;
        font-weight: 500;
        line-height: 180%;
        letter-spacing: -0.12px;
        color: #fff;

        &::before {
          content: '';
          margin-bottom: 12px;
          width: 52px;
          height: 20px;
          background: url(../@assets/title2.png) no-repeat;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
  }
}
