@import '../commonStyle.module.scss';
$COMPONENT_IMG_PATH: $STATIC_IMG_PATH + '/pass/interview';

$FADEIN_DURATION: 0.5s;
$FAQ_COUNT: 5;
$TRANSITION_DELAY: 0.06s;

// FAQ 영역
.wrap {
  margin-bottom: 178px;
}
.faqArea {
  .faqInner {
    width: 100%;
  }

  .title {
    display: block;
    margin-bottom: 48px;
    text-align: center;
    @include fontStyle($H1_B, $C_BLACK);
    transform: translateY(24px);
    transition: transform $FADEIN_DURATION ease-in-out,
      opacity $FADEIN_DURATION ease-in-out;
  }
}

// FAQ 영역
.faqList {
  .listItem {
    border-radius: 6px;
    border-bottom: 1px solid $C_COOL_GRAY_40;
    background-color: $C_WHITE;
    transform: translateY(24px);
    transition: transform $FADEIN_DURATION ease-in-out,
      opacity $FADEIN_DURATION ease-in-out;
  }

  @for $i from 1 through $FAQ_COUNT {
    .listItem#{$i} {
      transition-delay: #{($i + 1) * $TRANSITION_DELAY};
    }
  }
}

.listTitle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;

  &.on {
    &:after {
      background-image: url(../@assets/icon_arrow_top_light.png);
      transform: rotate(0);
    }
  }

  &:after {
    flex: none;
    width: 40px;
    height: 40px;
    background-image: url(../@assets/icon_arrow_top_light.png);
    transform: rotate(-180deg);
    background-size: cover;
    content: '';
  }
}

.question {
  display: inline-block;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: $C_JOBDA_BLACK;

  &::before {
    content: 'Q';
    display: inline-block;
    margin-right: 8px;
    font-size: 14px;
    font-weight: 500;
    color: $C_JOBDA_BLACK;
  }
}

.answer {
  overflow: hidden;
  height: 0;
  padding: 0 0 0 20px;
  background-color: $C_JOBDA_WHITE;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: $C_COOL_GRAY_80;

  &.on {
    padding: 20px;

    @for $i from 1 through 3 {
      &.type#{$i} {
        height: 100%;
      }
    }
  }
}

/* form link */
.formLink {
  border-bottom: 1px solid $C_GREEN_150;
  color: $C_GREEN_150;
}
