@import '../commonStyle.module.scss';

.commonBox {
  padding: 80px 0;
  background: linear-gradient(180deg, #63d77e 0%, #90e27e 100%);

  .descTitle {
    width: 312px;
    height: 38px;
    background-image: url(../@assets/profileTitle.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .descImg {
    flex-direction: column;

    &::after {
      content: '';
      display: block;
      margin: 40px 0 auto;
      width: 360px;
      height: 1132px;
      background: url(../@assets/profile2.png);
      background-size: contain;
      background-position: top center;
      background-repeat: no-repeat;
    }
  }
}
