.wrap {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  width: 480px;
  height: 480px;
}

.arrowWrap {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 80px;
  height: 93px;
  z-index: 99;
}

.button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--round, 1000000000px);
  background: var(--JOBDA-BLACK, #121619);
  box-shadow: 2px 4px 2px 0px rgba(255, 255, 255, 0.3) inset,
    4px 6px 2px 0px rgba(0, 0, 0, 0.25);

  &:disabled {
    border-radius: var(--round, 1000000000px);
    background: var(--COOL-GRAY-CG-60, #9fa4ab);

    .text {
      color: #e7e8ea; /* 텍스트 색상 설정 */
    }
  }
  &:not(:disabled) {
    .text {
      background: linear-gradient(105deg, #19d771 13.94%, #44cebd 106.43%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }

  .text {
    /* PC/H4/Bold */
    font-family: Pretendard;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.24px;
  }
}
@keyframes shake {
  0% {
    transform: translateX(-50%) rotate(0deg);
  }
  10% {
    transform: translateX(-50%) rotate(20deg);
  }
  15% {
    transform: translateX(-50%) rotate(5deg);
  }
  20% {
    transform: translateX(-50%) rotate(20deg);
  }
  25% {
    transform: translateX(-50%) rotate(5deg);
  }
  30% {
    transform: translateX(-50%) rotate(20deg);
  }
  35% {
    transform: translateX(-50%) rotate(5deg);
  }
  40% {
    transform: translateX(-50%) rotate(20deg);
  }
  45% {
    transform: translateX(-50%) rotate(5deg);
  }
  50% {
    transform: translateX(-50%) rotate(20deg);
  }
  55% {
    transform: translateX(-50%) rotate(5deg);
  }
  60% {
    transform: translateX(-50%) rotate(20deg);
  }
  65% {
    transform: translateX(-50%) rotate(5deg);
  }
  70% {
    transform: translateX(-50%) rotate(20deg);
  }
  75% {
    transform: translateX(-50%) rotate(5deg);
  }
  80% {
    transform: translateX(-50%) rotate(20deg);
  }
  85% {
    transform: translateX(-50%) rotate(15deg);
  }
  90% {
    transform: translateX(-50%) rotate(10deg);
  }
  95% {
    transform: translateX(-50%) rotate(5deg);
  }
  100% {
    transform: translateX(-50%) rotate(0deg);
  }
}

.shake {
  animation: shake 4s ease-in-out;
}
