@import './colors.scss';
// fontStyle
$display_1_b: 'display_1_b';
$display_1_sb: 'display_1_sb';
$display_2_b: 'display_2_b';
$display_2_sb: 'display_2_sb';
$display_3_b: 'display_3_b';
$display_3_sb: 'display_3_sb';
$heading_1_b: 'heading_1_b';
$heading_1_sb: 'heading_1_sb';
$heading_1_m: 'heading_1_m';
$heading_2_b: 'heading_2_b';
$heading_2_sb: 'heading_2_sb';
$heading_2_m: 'heading_2_m';
$heading_3_b: 'heading_3_b';
$heading_3_sb: 'heading_3_sb';
$heading_3_m: 'heading_3_m';
$title_1_b: 'title_1_b';
$title_1_sb: 'title_1_sb';
$title_1_m: 'title_1_m';
$title_2_b: 'title_2_b';
$title_2_sb: 'title_2_sb';
$title_2_m: 'title_2_m';
$title_3_b: 'title_3_b';
$title_3_sb: 'title_3_sb';
$title_3_m: 'title_3_m';
$body_1_b: 'body_1_b';
$body_1_sb: 'body_1_sb';
$body_1_m: 'body_1_m';
$body_2_b: 'body_2_b';
$body_2_sb: 'body_2_sb';
$body_2_m: 'body_2_m';
$body_3_b: 'body_3_b';
$body_3_sb: 'body_3_sb';
$body_3_m: 'body_3_m';
$caption_1_sb: 'caption_1_sb';
$caption_1_m: 'caption_1_m';
$caption_2_sb: 'caption_2_sb';
$caption_2_m: 'caption_2_m';

@mixin V2FontStyle($TYPE: $body_1_m, $TEXT_COLOR: $color_text_default) {
  color: $TEXT_COLOR;

  @if $TYPE == $display_1_b {
    $fontSize: 64px;
    font-size: $fontSize;
    font-weight: 700;
    line-height: 82px;
    letter-spacing: $fontSize * -0.01;
  } @else if $TYPE == $display_1_sb {
    $fontSize: 64px;
    font-size: $fontSize;
    font-weight: 600;
    line-height: 82px;
    letter-spacing: $fontSize * -0.01;
  } @else if $TYPE == $display_2_b {
    $fontSize: 56px;
    font-size: $fontSize;
    font-weight: 700;
    line-height: 72px;
    letter-spacing: $fontSize * -0.01;
  } @else if $TYPE == $display_2_sb {
    $fontSize: 56px;
    font-size: $fontSize;
    font-weight: 600;
    line-height: 72px;
    letter-spacing: $fontSize * -0.01;
  } @else if $TYPE == $display_3_b {
    $fontSize: 48px;
    font-size: $fontSize;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: $fontSize * -0.01;
  } @else if $TYPE == $display_3_sb {
    $fontSize: 48px;
    font-size: $fontSize;
    font-weight: 600;
    line-height: 60px;
  } @else if $TYPE == $heading_1_b {
    font-size: 40px;
    font-weight: 700;
    line-height: 52px;
  } @else if $TYPE == $heading_1_sb {
    font-size: 40px;
    font-weight: 600;
    line-height: 52px;
  } @else if $TYPE == $heading_1_m {
    font-size: 40px;
    font-weight: 500;
    line-height: 52px;
  } @else if $TYPE == $heading_2_b {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
  } @else if $TYPE == $heading_2_sb {
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
  } @else if $TYPE == $heading_2_m {
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
  } @else if $TYPE == $heading_3_b {
    font-size: 28px;
    font-weight: 700;
    line-height: 38px;
  } @else if $TYPE == $heading_3_sb {
    font-size: 28px;
    font-weight: 600;
    line-height: 38px;
  } @else if $TYPE == $heading_3_m {
    font-size: 28px;
    font-weight: 500;
    line-height: 38px;
  } @else if $TYPE == $title_1_b {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  } @else if $TYPE == $title_1_sb {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  } @else if $TYPE == $title_1_m {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
  } @else if $TYPE == $title_2_b {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  } @else if $TYPE == $title_2_sb {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  } @else if $TYPE == $title_2_m {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  } @else if $TYPE == $title_3_b {
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
  } @else if $TYPE == $title_3_sb {
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
  } @else if $TYPE == $title_3_m {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
  } @else if $TYPE == $body_1_b {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  } @else if $TYPE == $body_1_sb {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  } @else if $TYPE == $body_1_m {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  } @else if $TYPE == $body_2_b {
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
  } @else if $TYPE == $body_2_sb {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
  } @else if $TYPE == $body_2_m {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  } @else if $TYPE == $body_3_b {
    font-size: 13px;
    font-weight: 700;
    line-height: 18px;
  } @else if $TYPE == $body_3_sb {
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
  } @else if $TYPE == $body_3_m {
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
  } @else if $TYPE == $caption_1_sb {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
  } @else if $TYPE == $caption_1_m {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  } @else if $TYPE == $caption_2_sb {
    font-size: 11px;
    font-weight: 600;
    line-height: 14px;
  } @else if $TYPE == $caption_2_m {
    font-size: 11px;
    font-weight: 500;
    line-height: 14px;
  }
}

@mixin ellipsis($LINE: 1, $LINE_HEIGHT: 1.2em) {
  @if ($LINE == 1) {
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
  } @else {
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: $LINE;
    -webkit-box-orient: vertical;
    height: fit-content; // webkit 이외의 브라우저 대응
    line-height: $LINE_HEIGHT;
    text-overflow: ellipsis;
    word-break: break-word;
  }
}
