@import './variables/path.scss';

// Pretendard
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');

/*  Wemakeprice  */
@font-face {
  font-family: 'Wemakeprice';
  font-style: normal;
  font-weight: 700;
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-10-21@1.0/Wemakeprice-Bold.woff')
    format('woff');
}

// Rix
@font-face {
  font-family: 'RixYeoljeongdo_Regular';
  font-weight: normal;
  font-style: normal;
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2102-01@1.0/RixYeoljeongdo_Regular.woff')
    format('woff');
}
