@import '../index.module.scss';

@keyframes float {
  0% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(-15px) scale(1);
  }
  100% {
    transform: translateY(0) scale(1);
  }
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  height: calc(100vh - 64px);
  overflow: hidden;
  background: $V2C_GRAY_25;
  opacity: 0;
  transform: translateY(24px);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;

  &.on {
    transform: translateY(0);
    opacity: 1;

    .alarmList img {
      opacity: 1;
      transform: scale(1);

      &:nth-child(1) {
        transition-delay: 0.3s;
        animation: float 2s ease-in-out 0.75s infinite;
      }
      &:nth-child(2) {
        transition-delay: 0.1s;
        animation: float 2.2s ease-in-out 0.55s infinite;
      }
      &:nth-child(3) {
        transition-delay: 0.5s;
        animation: float 2.4s ease-in-out 0.95s infinite;
      }
      &:nth-child(4) {
        transition-delay: 0.2s;
        animation: float 2.6s ease-in-out 0.65s infinite;
      }
      &:nth-child(5) {
        transition-delay: 0.4s;
        animation: float 2.3s ease-in-out 0.85s infinite;
      }
      &:nth-child(6) {
        transition-delay: 0.15s;
        animation: float 2.5s ease-in-out 0.6s infinite;
      }
      &:nth-child(7) {
        transition-delay: 0.35s;
        animation: float 2.1s ease-in-out 0.8s infinite;
      }
      &:nth-child(8) {
        transition-delay: 0.25s;
        animation: float 2.7s ease-in-out 0.7s infinite;
      }
      &:nth-child(9) {
        transition-delay: 0.45s;
        animation: float 2.2s ease-in-out 0.9s infinite;
      }
      &:nth-child(10) {
        transition-delay: 0.18s;
        animation: float 2.4s ease-in-out 0.63s infinite;
      }
      &:nth-child(11) {
        transition-delay: 0.38s;
        animation: float 2.6s ease-in-out 0.83s infinite;
      }
      &:nth-child(12) {
        transition-delay: 0.28s;
        animation: float 2.3s ease-in-out 0.73s infinite;
      }
    }
  }

  .contentInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 240px;
  }

  .alarmList {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    
    img {
      position: absolute;
      transition: all 0.45s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      width: auto;
      height: auto;
      object-fit: contain;
      opacity: 0;
      transform: scale(0);

      &:nth-child(1) {
        top: 45px;
        left: 10px;
        width: 180px;
        height: 54px;
      }

      &:nth-child(2) {
        top: 55px;
        right: -20px;
        width: 132px;
        height: 24px;
      }

      &:nth-child(3) {
        top: 142px;
        left: -14px;
        width: 22vw;
      }

      &:nth-child(4) {
        top: 148px;
        right: -76px;
        width: 260px;
        height: 72px;
      }

      &:nth-child(5) {
        top: 540px;
        left: -16px;
        width: 152px;
        height: 27px;
      }

      &:nth-child(6) {
        top: 564px;
        right: -52px;
        width: 280px;
        height: 72px;
      }

      &:nth-child(7) {
        top: 642px;
        left: -13px;
        width: 140px;
        height: 40px;
      }

      &:nth-child(8) {
        top: 713px;
        right: -29px;
        width: 330px;
        height: 92px;
      }
    }
  }
}
