@import '../accaIntro.module.scss';

.companyList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.gateInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 304px;
  height: 100%;
  border-radius: 16px;
  box-shadow: none;
  border: 1px solid #e7e8ea;
  background: #fff;

  .thumbnail {
    width: 100%;
    height: 196px;
    border-radius: 16px 16px 0 0;
    background-color: #f4f4f5;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .listInner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    padding: 32px;

    .titleInner {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 24px;

      .number {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 16px;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        background-color: #f4f4f5;
        @include fontStyle($B1_B, $C_COOL_GRAY_60);
      }

      .title {
        @include fontStyle($H5_B, $C_JOBDA_BLACK);
      }
    }

    .list {
      display: flex;
      gap: 8px;
      @include fontStyle($B3_M, $C_COOL_GRAY_70);

      &.list:not(:last-of-type) {
        margin-bottom: 8px;
      }
    }

    .link {
      display: flex;
      width: 100%;
      height: 38px;
      margin-top: 24px;
      @include fontStyle($B3_B, $C_WHITE);
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background: $C_JOBDA_BLACK;

      &:hover {
        background: $C_COOL_GRAY_90;
        color: $C_WHITE;
      }
    }
  }
}

.errorBox {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
  margin-top: 24px;
  width: 100%;
  gap: 4px;
  border-top: 1px solid #e7e8ea;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.12px;
}
