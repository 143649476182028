@import '../../commonStyle.module.scss';

.filterArea {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  padding: 24px 16px;
  gap: 8px;
  background-color: #fff;
  overflow-x: scroll;
  overflow-y: hidden;
  z-index: 9;

  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

// 옵션 버튼 영역
.option {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  height: 44px;
  border-radius: 24px;
  cursor: pointer;
  border: 2px solid transparent;
  background-color: #f7f7f7;
  white-space: pre;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #838486;

  .count {
    display: flex;
    align-items: center;
    margin-left: 6px;
    padding: 0 4px;
    height: 16px;
    background-color: #e9eaeb;
    border-radius: 4px;
    font-size: 11px;
    font-weight: 600;
    line-height: 15px;
    color: #838486;
  }

  &.on {
    background-color: #fff;
    border: 2px solid $C_COOL_GRAY_70;
    color: #333436;

    .count {
      background-color: #12b886;
      color: #fff;
    }
  }
}
