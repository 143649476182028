@import '../commonStyle.module.scss';

.wrap {
  padding-bottom: 56px;
}

// 타이틀
.sectionTitle {
  font-family: $F_RIX;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.24px;
  color: #595ff4;
}

// 응원 메시지 영역
.slideCard {
  width: 237px;
}

.messageArea {
  overflow: hidden;
  width: 100%;
}

.messageInner {
  flex: none;
  padding: 0 12px;
}

.messageWrap {
  display: flex;
  flex-direction: column;
  width: 237px;
  height: 204px;
  padding: 24px;
  border: 3px solid #4021ff;
  border-radius: 16px;
  background-color: #191b20;
  text-align: left;

  &.secondary {
    border-color: #6120cb;
  }
}

.companyName {
  flex: none;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: $C_WHITE;
}

.comment {
  flex: none;
  margin-top: 20px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.12px;
  color: $C_WHITE;
}

.who {
  flex: none;
  margin-top: 13px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.14px;
  color: $C_WHITE;
}
