// Created by ksy0105 on 2022-04-12 ATS

.container {
  position: relative;
  padding: 7px 0;

  input[type='range'] {
    position: absolute;
    pointer-events: none;
    -webkit-appearance: none;
    z-index: 2;
    height: 10px;
    width: 100%;
    opacity: 0;

    &::-webkit-slider-thumb {
      pointer-events: all;
      width: 24px;
      height: 24px;
      border-radius: 0;
      border: 0 none;
      background-color: red;
      cursor: pointer;
      -webkit-appearance: none;
    }
  }

  .slider {
    position: relative;
    z-index: 1;
    height: 10px;
    margin: 0 12px;

    > .track {
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: #dee2e6;
    }

    > .range {
      position: absolute;
      z-index: 2;
      top: 0;
      bottom: 0;
      border-radius: 5px;
      background-color: #343a40;
    }

    > .dots {
      position: absolute;
      z-index: 2;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      .dot {
        position: absolute;
        width: 10px;
        height: 10px;
        background: #CFD1D5;
        border-radius: 10px;
        border: 1px solid #E7E8EA;
        transform: translateX(-5px);

        &.active {
          background: #121619;
          border-color: #44474B;
        }

        .value {
          position: absolute;
          bottom: calc(0px - 15px - 16px);
          left: 50%;
          width: max-content;
          font-family: 'Pretendard', sans-serif;
          font-size: 11px;
          line-height: 14px;
          letter-spacing: -0.07px;
          color: #A4A4A4;
          transform: translateX(-50%);
        }
      }
    }

    > .thumb {
      position: absolute;
      z-index: 3;
      width: 24px;
      height: 24px;
      background-color: white;
      border-radius: 50%;
      border: 2px solid #343a40;

      &.active {
        box-shadow: 0 0 0 3px rgba(134, 142, 150, 0.3);
      }

      &.left {
        transform: translate(-12px, -7px);
      }

      &.right {
        transform: translate(12px, -7px);
      }

      .tooltip {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        background: #343a40;
        border-radius: 4px;
        padding: 4px 7px;
        font-weight: 700;
        font-size: 13px;
        line-height: 18px;
        color: #ffffff;

        .arrow {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          border-style: solid;
          height: 0;
          width: 0;
        }

        &.tooltip-top {
          top: calc(-100% - 20px);

          .arrow {
            bottom: -7px;
            border-color: #343a40 transparent transparent transparent;
            border-width: 7px 7px 0 7px;
          }
        }

        &.tooltip-bottom {
          bottom: calc(-100% - 20px);

          .arrow {
            top: -7px;
            border-color: transparent transparent #343a40 transparent;
            border-width: 0 7px 7px 7px;
          }
        }
      }
    }

    &.black {
      > .range {
        background-color: #44474B;
      }

      > .thumb {
        border-color: #44474B;

        &.left {
          z-index: 3;
        }

        &.disabledRightValue {
          border-color: #9FA4AB;
          box-shadow: none;
          z-index: 2;
        }
      }
    }

    &.sky {
      > .range {
        background-color: #5cadd0;
      }

      > .thumb {
        border-color: #5cadd0;

        &.active {
          box-shadow: 0 0 0 3px rgba(92, 173, 208, 0.3);
        }
      }

      > .dots {
        .dot {
          &.active {
            border-color: #5cadd0;
          }
        }
      }

      .tooltip {
        background: #cfe7f1;
        color: #5cadd0;

        &.tooltip-top {
          .arrow {
            border-color: #cfe7f1 transparent transparent transparent;
          }
        }

        &.tooltip-bottom {
          .arrow {
            border-color: transparent transparent #cfe7f1 transparent;
          }
        }
      }
    }

    &.blue {
      > .range {
        background-color: #5c68d0;
      }

      > .thumb {
        border-color: #5c68d0;

        &.active {
          box-shadow: 0 0 0 3px rgba(92, 104, 208, 0.3);
        }
      }

      > .dots {
        .dot {
          &.active {
            border-color: #5c68d0;
          }
        }
      }

      .tooltip {
        background: #d5d7f2;
        color: #5c68d0;

        &.tooltip-top {
          .arrow {
            border-color: #d5d7f2 transparent transparent transparent;
          }
        }

        &.tooltip-bottom {
          .arrow {
            border-color: transparent transparent #d5d7f2 transparent;
          }
        }
      }
    }

    &.purple {
      > .range {
        background-color: #a45cd0;
      }

      > .thumb {
        border-color: #a45cd0;

        &.active {
          box-shadow: 0 0 0 3px rgba(164, 92, 208, 0.3);
        }
      }

      > .dots {
        .dot {
          &.active {
            border-color: #a45cd0;
          }
        }
      }

      .tooltip {
        background: #ecdef5;
        color: #a45cd0;

        &.tooltip-top {
          .arrow {
            border-color: #ecdef5 transparent transparent transparent;
          }
        }

        &.tooltip-bottom {
          .arrow {
            border-color: transparent transparent #ecdef5 transparent;
          }
        }
      }
    }

    &.green {
      > .track {
        background-color: #D1D1D1;
      }

      > .range {
        background-color:#6CDD83;
      }

      > .thumb {
        border-color: #6CDD83;

        &.active {
          box-shadow: 0 0 0 4px rgba(#6CDD83, 0.3);
        }
      }

      > .dots {
        .dot {
          border-color: #CFD1D5;
          background-color: #9FA4AB;

          &.active {
            border-color: #6CDD83;
            background-color: #52C564;
          }
        }
      }

      .tooltip {
        background-color: #C3F2CD;
        color: #3ed160;

        &.tooltip-top {
          .arrow {
            border-color: #C3F2CD transparent transparent transparent;
          }
        }

        &.tooltip-bottom {
          .arrow {
            border-color: transparent transparent #C3F2CD transparent;
          }
        }
      }
    }
  }
}