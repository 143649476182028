@import '../matchIntroduce.module.scss';

.commonBox {
  padding-top: 0;
  overflow: hidden;

  .mainTitle {
    margin-top: 12px;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }

  .subTitle {
    margin: 0 0 16px 0; //기존 마진 탑 0 처리
  }
}

/* 매칭 내용 */
.matchingInner {
  position: relative;
  padding-top: 130px;
  display: flex;
  flex-direction: column;
  width: 328px;
  gap: 90px;

  &::after {
    content: '';
    position: absolute;
    bottom: -162px;
    right: 50%;
    transform: translateX(50%);
    display: inline-block;
    width: 8px;
    height: 147px;
    background-image: url(../@assets/line03.png);
    background-size: cover;
    background-position: center;
  }

  .contentsInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 0;

    &.firstContents {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: -360px;
        right: 50%;
        transform: translateX(50%);
        display: inline-block;
        width: 8px;
        height: 90px;
        background-image: url(../@assets/line02.png);
        background-size: cover;
        background-position: center;
      }

      &::before {
        content: '';
        position: absolute;
        bottom: 220px;
        right: 50%;
        transform: translateX(50%);
        display: inline-block;
        width: 8px;
        height: 100px;
        background-image: url(../@assets/line01.png);
        background-size: cover;
        background-position: center;
      }
    }
  }

  .matchingBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .subText {
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;

      &::before {
        content: '';
        display: block;
        width: 48px;
        height: 48px;
        margin: 0 auto 16px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    &.firstBox {
      .subText {
        &::before {
          background-image: url(../@assets/icon_thumbs_up_light.png);
        }
      }

      &::after {
        content: '';
        display: inline-block;
        width: 328px;
        height: 262px;
        background-image: url(../@assets/matching1.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    &.secondBox {
      .subText {
        &::before {
          background-image: url(../@assets/icon_corporate_fare_light.png);
        }
      }

      &::after {
        content: '';
        display: inline-block;
        width: 328px;
        height: 262px;
        background-image: url(../@assets/matching2.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}

/* 큐레이터 */
.curatorInner {
  margin-top: 211px;
}
