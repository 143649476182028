@import '../../accaIntro.module.scss';

.layer {
  position: relative;
}

.videoArea {
  width: 100%;
  height: 56.22vw;
  border: 0;
  background-color: $C_COOL_GRAY_50;
}

.btnClose {
  position: absolute;
  top: -12px;
  right: 0;
  transform: translateY(-100%);

  svg {
    width: 40px;
    height: 40px;
  }
}
