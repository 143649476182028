@import '../accaIntro.module.scss';

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: $Z_INDEX_FIXED;

  &.dimmed {
    background-color: rgba(0, 0, 0, 0.6);
  }

  .modalLayer {
    position: relative;

    @media all and (max-width: $MOBILE_SCREEN) {
      width: 100%;
    }
  }
}
