@import '../../styles/base.scss';
@import '../../styles/common.scss';
@import '../../styles/font.scss';
@import '../../styles/mixins.scss';
@import '../../styles/reset.scss';

/* 모바일 폰트 */
* {
  font-family: $F_PRETENDARD !important;
}

/* 공통 box */
.commonBox {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0 112px 0;

  .tagBox {
    position: absolute;
    top: -47px;
    left: 0;
    display: flex;
    align-items: flex-end;
    padding-left: 22px;
    width: 240px;
    height: 48px;
    border-radius: 16px 16px 0px 0px;
    gap: 16px;
    font-size: 18px;
    font-weight: 700;
    color: $C_JOBDA_BLACK;

    .num {
      font-size: 14px;
      font-weight: 500;
      color: $C_JOBDA_BLACK;
    }
  }

  /* tag box color */
  &.greenTag {
    background: linear-gradient(180deg, #63d77e 0%, #90e27e 100%);
  }

  &.whiteTag {
    background: $C_WHITE;
  }

  &.blackTag {
    background: $C_JOBDA_BLACK;
  }
}

/* 공통 font */
.basicTitleInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  .basicTitle {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color: $C_JOBDA_BLACK;
  }

  .subTitle {
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    color: $C_JOBDA_BLACK;

    &.reversal {
      color: $C_WHITE;
    }
  }
}

.basicText {
  font-size: 20px;
  font-weight: 700;
  color: $C_JOBDA_BLACK;

  .num {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    width: 32px;
    height: 32px;
    background-color: $C_JOBDA_BLACK;
    border-radius: 16px;
    @include fontStyle($B3_B, $C_WHITE);
  }

  &.bgBlack {
    color: $C_WHITE;

    .num {
      background-color: $C_WHITE;
      color: $C_JOBDA_BLACK;
    }
  }
}

/* 기본 underline 버튼 */
.btnBasic {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  font-size: 14px;
  font-weight: 500;
  color: $C_COOL_GRAY_50;

  .title {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $C_COOL_GRAY_50;
    }
  }
}

/* 버튼 기본 - black, join - green */
.btnLink {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 312px;
  height: 72px;
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.6);
  font-size: 18px;
  font-weight: 700;
  color: $C_WHITE;

  svg {
    margin-left: 8px;
  }

  &.btnJoin {
    background: $C_GREEN_100;
    border: none;
    font-size: 18px;
    font-weight: 700;
    color: $C_JOBDA_BLACK;
  }
}
